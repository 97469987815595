import { Box, BoxProps } from "@mantine/core";
import styles from "./PulseIndicator.module.css";

interface PulseIndicatorProps extends BoxProps {
  color?: string;
}

export default function PulseIndicator({
  color = "currentColor",
  className,
  ...props
}: PulseIndicatorProps) {
  return (
    <Box
      h={4}
      w={4}
      className={`${styles.pulse} ${className || ""}`}
      style={{ background: color, borderRadius: "100%" }}
      {...props}
    />
  );
}
