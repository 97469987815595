// lessonsUtil.ts
import { LessonBuilderLessonOut } from "../api/nisaAPITypes";

export interface GroupedLessons {
  [lessonSource: string]: {
    [grade: string]: {
      [module: string]: {
        [topic: string]: LessonBuilderLessonOut[];
      };
    };
  };
}

// Group lessons based on lesson source
// and then waterfall down based on grade,
// module, topic, and then lesson.
export function groupLessons(
  lessons: LessonBuilderLessonOut[],
): GroupedLessons {
  return lessons.reduce((acc, lesson) => {
    const { lesson_source, grade, module_name, topic_name } =
      lesson.source_lesson;

    if (!acc[lesson_source]) {
      acc[lesson_source] = {};
    }
    if (!acc[lesson_source][grade]) {
      acc[lesson_source][grade] = {};
    }
    if (!acc[lesson_source][grade][module_name]) {
      acc[lesson_source][grade][module_name] = {};
    }
    if (!acc[lesson_source][grade][module_name][topic_name]) {
      acc[lesson_source][grade][module_name][topic_name] = [];
    }

    acc[lesson_source][grade][module_name][topic_name].push(lesson);
    return acc;
  }, {} as GroupedLessons);
}
