import { Box, Container, Flex } from "@mantine/core";
import React from "react";

export const SingleColumnLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Flex
      direction="column"
      justify="flex-start"
      align="center"
      mih="100vh"
      gap="sm"
    >
      {children}
    </Flex>
  );
};

export const SingleColumnLayoutFooter: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box
      w="100%"
      mt="auto"
      style={(theme) => ({ borderTop: `1px solid ${theme.colors.gray[1]}` })}
    >
      <Container size="xl">{children}</Container>
    </Box>
  );
};

export const SingleColumnLayoutMain: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Container size="xl" mt="xl">
      {children}
    </Container>
  );
};
