import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LessonOut,
  LessonSource,
  UnpackerSessionInitIn,
} from "../../../api/nisaAPITypes";
import {
  createUnpackerSession,
  fetchLessonsBySource,
  fetchNextLessons,
  fetchRecentTeacherData,
  fetchTeacher,
} from "../../../api/nisaAPI";
import {
  Anchor,
  Button,
  Container,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import LessonSelector from "../../LessonSelector";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext";
import { UnpackerLayout } from "./UnpackerLayout";
import { getCachedUID } from "../../../utils/sessionUtils";

// Currently we only support Illustrative Mathematics
// since the data used for displaying the lesson we can
// only get from IM (Eureka Math doesn't have an API and we
// only have PDFs of the lessons. So we'd need to do some work
// to format them into something usable by the unpacker.)
export const lessonSourceOptions: LessonSource[] = ["illustrative_mathematics"];

const UnpackerLessonsPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedLesson, setSelectedLesson] = useState<LessonOut | null>(null);
  const [teacherContext, setTeacherContext] = useState("");
  const selectedLessonRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<{ openModal: () => void }>(null);
  const { currentUser } = useAuth();
  const userUID = getCachedUID();

  const { mutateAsync } = useMutation({
    mutationFn: async (payload: UnpackerSessionInitIn) => {
      const token = await currentUser?.getIdToken();
      return await createUnpackerSession(payload, token);
    },
  });

  const { data: teacherInfo } = useQuery({
    queryKey: ["unpacker", "teacherInfo", userUID],
    queryFn: async () => {
      if (!userUID) return;
      const token = await currentUser.getIdToken();
      return fetchTeacher(userUID, token);
    },
    enabled: Boolean(userUID),
  });

  const { data: recentTeacherData } = useQuery({
    queryKey: ["unpacker", "teacherData", userUID],
    queryFn: async () => {
      if (!teacherInfo) return;
      const token = await currentUser.getIdToken();
      return await fetchRecentTeacherData(teacherInfo.id, token);
    },
    enabled: !!teacherInfo,
  });

  const { data: nextLesson } = useQuery({
    queryKey: ["unpacker", "nextLesson", userUID],
    queryFn: async () => {
      if (
        !teacherInfo ||
        !teacherInfo.last_lesson_id ||
        !teacherInfo.last_lesson_source
      )
        return;
      const token = await currentUser.getIdToken();
      const nextLessons = await fetchNextLessons(
        "illustrative_mathematics",
        teacherInfo.last_lesson_id,
        token,
        1,
      );
      if (nextLessons.length === 0) {
        return;
      }

      // We do this to match the output of LessonSelector since that expects a
      // LessonOut, which has slightly different properties than the
      // LessonModelOut.  We should probably spend some time trying to see if we
      // can unify these models and/or create separate endpoints for UI and
      // microservice APIs.
      const allLessons = await fetchLessonsBySource(
        "illustrative_mathematics",
        token,
      );
      const nextLessonID = nextLessons[0].lesson_model.id;
      return allLessons.find((lesson: LessonOut) => lesson.id === nextLessonID);
    },
    enabled: !!teacherInfo,
  });

  // Update teacherContext whenever we get new recentTeacherData
  // with the action steps
  useEffect(() => {
    if (recentTeacherData && recentTeacherData.action_steps) {
      setTeacherContext(recentTeacherData.action_steps.join(", "));
    }
  }, [recentTeacherData]);

  useEffect(() => {
    if (nextLesson) {
      setSelectedLesson(nextLesson);
    }
  }, [nextLesson]);

  // Effect to scroll to the selected lesson content when it appears
  useEffect(() => {
    if (selectedLesson && selectedLessonRef.current) {
      selectedLessonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedLesson]);

  const startLesson = async () => {
    if (selectedLesson) {
      try {
        const sessionInitPayload: UnpackerSessionInitIn = {
          lesson_id: selectedLesson.id,
          context: teacherContext,
        };
        // TODO: This will need to be a mutation call or we store local context
        const session = await mutateAsync(sessionInitPayload);

        // Then navigate to lesson page
        navigate(`/unpacker/lesson/${session.id}`);
      } catch (error) {
        console.error("Failed to initialize session:", error);
        notifications.show({
          color: "red",
          title: "Failed to start lesson",
          message: "Please try again.",
        });
      }
    }
  };

  return (
    <UnpackerLayout footerRef={footerRef}>
      <Container size="lg" py="8rem" px="md">
        <Stack maw={768} mx="auto" mb={64} align="center">
          <Stack gap="xs">
            <Paper
              bg="yellow.3"
              px="lg"
              py="md"
              style={{ display: "inline-block" }}
            >
              <Group>
                <Image
                  src="/open-box.png"
                  alt="Open box"
                  style={{ height: 80, width: 80 }}
                />
                <Title order={1} size={80}>
                  unpacker
                </Title>
              </Group>
            </Paper>
            <Anchor
              size="xl"
              c="gray.6"
              fw={100}
              onClick={() => footerRef.current?.openModal()}
              ta="left"
            >
              an experiment
            </Anchor>
          </Stack>
        </Stack>
        {/*
          Currently we only support Illustrative Mathematics
          since the data used for displaying the lesson we can
          only get from IM (Eureka Math doesn't have an API and we
          only have PDFs of the lessons. So we'd need to do some work
          to format them into something usable by the unpacker.)
        */}
        <Stack maw={640} mx="auto">
          <LessonSelector
            lessonSource="illustrative_mathematics"
            onLessonChange={(lesson) => {
              setSelectedLesson(lesson);
            }}
          />
        </Stack>

        {selectedLesson && (
          <Stack ref={selectedLessonRef} maw={640} mx="auto" mt="xl">
            <Paper shadow="sm" p="md" radius="md" withBorder>
              <Stack>
                <Title order={3}>{selectedLesson.display_name}</Title>
                <Group>
                  <Text fw={500}>Grade:</Text>
                  <Text>{selectedLesson.grade}</Text>
                </Group>
                {selectedLesson.curriculum_name && (
                  <Group>
                    <Text fw={500}>Curriculum:</Text>
                    <Text>{selectedLesson.curriculum_name}</Text>
                  </Group>
                )}
                <Group>
                  <Text fw={500}>Module:</Text>
                  <Text>{selectedLesson.module_name}</Text>
                </Group>
                <Group>
                  <Text fw={500}>Topic:</Text>
                  <Text>{selectedLesson.topic_name}</Text>
                </Group>
                <Group>
                  <Text fw={500}>Lesson:</Text>
                  <Text>{selectedLesson.lesson_name}</Text>
                </Group>
              </Stack>
            </Paper>

            <Paper shadow="sm" p="md" radius="md" withBorder>
              <Stack>
                <Text fw={500} size="sm">
                  Optional: Add Context About You or Your Class
                </Text>
                <Textarea
                  placeholder="Tell me about your teaching style, your students, or any specific goals for this lesson..."
                  minRows={4}
                  value={teacherContext}
                  onChange={(e) => setTeacherContext(e.target.value)}
                />
                <Button variant="filled" onClick={startLesson} fullWidth>
                  Start Unpacking
                </Button>
              </Stack>
            </Paper>
          </Stack>
        )}
      </Container>
    </UnpackerLayout>
  );
};

export default UnpackerLessonsPage;
