import axios from "axios";
import {
  LessonBuilderGenerateSegmentsOut,
  LessonBuilderLessonOut,
  LessonBuilderLessonsOut,
  LessonBuilderLessonUpdateIn,
  LessonOut,
  LessonSource,
  TeacherOut,
  TeacherPatchIn,
  UnpackerNextResponseIn,
  UnpackerNextResponseOut,
  LessonModelOut,
  TeacherDataOut,
  UnpackerScheduleTextIn,
  UnpackerSessionInitIn,
  UnpackerSessionModel,
} from "../api/nisaAPITypes";

export const fetchRssLink = async (
  teacherId: string,
  accessToken: string,
): Promise<string> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/rss/encode/${teacherId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const encryptedUid = response.data.encrypted_teacher_uid;
    return `${process.env.REACT_APP_API_URL}/rss/${encryptedUid}`;
  } catch (err) {
    console.error("Failed to fetch RSS link:", err);
    throw new Error("Could not fetch RSS link");
  }
};

/**
 * Fetches the list of all teachers.
 *
 * @param token - The authentication token.
 * @returns A promise that resolves to an array of TeacherOut.
 */
export const fetchAllTeachers = async (
  token: string,
): Promise<TeacherOut[]> => {
  const response = await axios.get<TeacherOut[]>(
    `${process.env.REACT_APP_API_URL}/teacher/info`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

/**
 * Fetches data about a specific teacher.
 *
 * @param teacherId - The ID for the teacher you want data for.
 * @param token - The authentication token.
 * @returns A promise that resolves to a TeacherOut.
 */
export const fetchTeacher = async (
  teacherId: string,
  token: string,
): Promise<TeacherOut> => {
  const response = await axios.get<TeacherOut>(
    `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

/**
 * Fetches most recent teacher data for a teacher.
 *
 * @param teacherId - The ID for the teacher you want data for.
 * @param token - The authentication token.
 * @returns A promise that resolves to a TeacherDataOut.
 */
export const fetchRecentTeacherData = async (
  teacherId: string,
  token: string,
): Promise<TeacherDataOut> => {
  const response = await axios.get<TeacherDataOut>(
    `${process.env.REACT_APP_API_URL}/teacher/${teacherId}/data/recent`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

/**
 * Activates a teacher for a given action and week.
 *
 * @param teacherId - The unique identifier of the teacher.
 * @param action - The activation action to perform.
 * @param weekStart - The start date of the week (formatted as a string).
 * @param token - The authentication token.
 * @returns A promise that resolves to the response data.
 */
export const activateTeacherAction = async (
  teacherId: string,
  action: string,
  weekStart: string,
  token: string,
): Promise<any> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/teacher/${teacherId}/activate-${action}`,
    { week_start: weekStart },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

// Function to fetch Lesson Builder lessons for the
// authenticated teacher.
export const fetchLessonsBySource = async (
  lessonSource: LessonSource,
  token: string,
): Promise<LessonOut[]> => {
  const response = await axios.get<LessonOut[]>(
    `${process.env.REACT_APP_API_URL}/lesson/${lessonSource}`,
    {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  );
  return response.data;
};

export const getLesson = async (
  lessonSource: LessonSource,
  lessonId: string,
  token?: string,
  enhanced_lesson_info: boolean = false,
): Promise<LessonModelOut> => {
  const response = await axios.get<LessonModelOut>(
    `${process.env.REACT_APP_API_URL}/lesson/${lessonSource}/${lessonId}?enhanced_lesson_info=${enhanced_lesson_info}`,
    {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  );
  return response.data;
};

// Function to fetch a number of next lessons
export const fetchNextLessons = async (
  lessonSource: LessonSource,
  lessonId: string,
  token: string,
  numLessons: number,
): Promise<LessonModelOut[]> => {
  try {
    const response = await axios.get<LessonModelOut[]>(
      `${process.env.REACT_APP_API_URL}/lesson/${lessonSource}/${lessonId}/next?num_lessons=${numLessons}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch next lessons");
  }
};

// Function to get or create a Lesson Builder lesson.
export const getOrCreateLessonBuilderLesson = async (
  lessonData: {
    source_id: string;
    coach_action?: string;
  },
  token?: string,
): Promise<{ id: string }> => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/lesson-builder/lessons`,
    lessonData,
    {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  );
  return response.data;
};

// Function to fetch a Lesson Builder lesson.
export const fetchLessonBuilderLesson = async (
  lessonId: string,
  token?: string,
): Promise<LessonBuilderLessonOut> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/lesson-builder/lessons/${lessonId}`,
    {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  );
  return response.data;
};

// Function to update a Lesson Builder lesson.
export const updateLessonBuilderLesson = async (
  lessonId: string,
  data: LessonBuilderLessonUpdateIn,
  token?: string,
): Promise<LessonBuilderLessonOut> => {
  const res = await axios.put(
    `${process.env.REACT_APP_API_URL}/lesson-builder/lessons/${lessonId}`,
    data,
    {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  );
  return res.data;
};

// Function to generate segments for a Lesson Builder lesson.
export const generateLessonBuilderLessonSegments = async (
  lessonId: string,
  token?: string,
): Promise<LessonBuilderGenerateSegmentsOut> => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/lesson-builder/lessons/${lessonId}/generate-segments`,
    {},
    {
      headers: token ? { Authorization: `Bearer ${token}` } : {},
    },
  );
  return res.data;
};

// Function to fetch Lesson Builder lessons for the
// authenticated teacher.
export const fetchLessonBuilderLessons = async (
  token: string,
): Promise<LessonBuilderLessonsOut> => {
  try {
    const response = await axios.get<LessonBuilderLessonsOut>(
      `${process.env.REACT_APP_API_URL}/lesson-builder/lessons`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch lessons:", error);
    throw new Error("Could not fetch lessons");
  }
};

// Function to patch update a teacher
export const patchUpdateTeacher = async (
  teacherId: string,
  updatedData: TeacherPatchIn,
  token: string,
): Promise<TeacherOut> => {
  try {
    const response = await axios.patch<TeacherOut>(
      `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`,
      updatedData,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update teacher");
  }
};

export const createUnpackerSession = async (
  unpackerData: UnpackerSessionInitIn,
  token?: string,
): Promise<UnpackerSessionModel> => {
  try {
    const response = await axios.post<UnpackerSessionModel>(
      `${process.env.REACT_APP_API_URL}/unpacker/session`,
      unpackerData,
      {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      },
    );
    return response.data;
  } catch {
    throw new Error("Could not create unpacker session");
  }
};

export const fetchUnpackerSession = async (
  unpackerSessionId: string,
  token?: string,
): Promise<UnpackerSessionModel> => {
  try {
    const response = await axios.get<UnpackerSessionModel>(
      `${process.env.REACT_APP_API_URL}/unpacker/session/${unpackerSessionId}`,
      {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      },
    );
    return response.data;
  } catch {
    throw new Error("Could not fetch unpacker session");
  }
};

export const continueUnpackerSession = async (
  unpackerSessionId: string,
  nextResponse: UnpackerNextResponseIn,
  token?: string,
  audioFile?: File,
): Promise<UnpackerNextResponseOut> => {
  try {
    const formData = new FormData();
    formData.append("next_response_in_json", JSON.stringify(nextResponse));

    if (audioFile) {
      formData.append("audio_file", audioFile);
    }

    const response = await axios.post<UnpackerNextResponseOut>(
      `${process.env.REACT_APP_API_URL}/unpacker/session/${unpackerSessionId}/next`,
      formData,
      {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      },
    );
    return response.data;
  } catch {
    throw new Error("Could not continue unpacker session");
  }
};

export const scheduleUnpackerTextMessage = async (
  unpackerSessionId: string,
  unpackerScheduleTextIn: UnpackerScheduleTextIn,
  token: string,
): Promise<UnpackerSessionModel> => {
  try {
    const response = await axios.patch<UnpackerSessionModel>(
      `${process.env.REACT_APP_API_URL}/unpacker/session/${unpackerSessionId}/schedule_text`,
      unpackerScheduleTextIn,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  } catch (error: any) {
    const errorMsg =
      error.response?.data?.detail ||
      error.message ||
      "Could not schedule unpacker text message";
    throw new Error(errorMsg);
  }
};
