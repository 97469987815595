import { MouseEvent, useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { BaseHeader } from "../BaseHeader";
import { getCachedUserFullName } from "../../utils/sessionUtils";
import { useNavigate } from "react-router-dom";

type HeaderProps = {
  onLogout?: () => void;
};

export function Header({ onLogout = () => {} }: HeaderProps): JSX.Element {
  const [userFullName, setUserFullName] = useState<string>("User");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  // Fetch user data
  useEffect(() => {
    const fullName = getCachedUserFullName();
    if (fullName) {
      setUserFullName(fullName);
    }
  }, []);
  // Handle logout click
  const handleLogout = (event: MouseEvent<HTMLButtonElement>) => {
    // Clear session storage, which includes clientID
    sessionStorage.clear();
    setIsDropdownOpen(false);
    event.preventDefault();
    onLogout();
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <BaseHeader>
      <div className="relative">
        <Button
          onClick={toggleDropdown}
          variant="ghost"
          className="text-white font-quicksand border-none
                     hover:border hover:border-blue-200"
        >
          Hi, {userFullName}
        </Button>
        {isDropdownOpen && (
          <div
            className="absolute right-0 mt-2 w-48 bg-white
                       rounded-md shadow-lg py-1 z-50"
          >
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => navigate("/teacher")}
            >
              Dashboard
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => navigate("/teacher/lesson_builder")}
            >
              Lesson Builder
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => navigate("/teacher/lesson_builder/lessons")}
            >
              Lesson Builder Lessons
            </button>
            <button
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              onClick={() => navigate("/teacher/account")}
            >
              Account Configuration
            </button>
            <button
              type="button"
              className="block px-4 py-2 text-sm text-gray-700
                         hover:bg-gray-100 w-full text-left"
              onClick={handleLogout}
            >
              Log out
            </button>
          </div>
        )}
      </div>
    </BaseHeader>
  );
}
