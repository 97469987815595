import { useEffect, useState } from "react";
import { Paper, Text } from "@mantine/core";
import { UnpackerNextResponseOut } from "@/api/nisaAPITypes";

interface TranscriptViewerProps {
  messages: UnpackerNextResponseOut[];
}

export default function TranscriptViewer({ messages }: TranscriptViewerProps) {
  const [displayedText, setDisplayedText] = useState("");
  const currentMessage = messages[messages.length - 1];

  useEffect(() => {
    if (!currentMessage?.speech) return;

    setDisplayedText(""); // Reset text when message changes
    let currentIndex = 0;
    const speech = currentMessage.speech;
    const streamInterval = setInterval(() => {
      if (currentIndex <= speech.length) {
        setDisplayedText(speech.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(streamInterval);
      }
    }, 20); // Slightly faster typing

    return () => clearInterval(streamInterval);
  }, [currentMessage]);

  const getBubbleColor = (speaker: string) => {
    switch (speaker.toLowerCase()) {
      case "webby":
        return "yellow.1";
      case "bloo":
        return "blue.1";
      default:
        return "gray.1";
    }
  };

  const getBubbleBorderColor = (speaker: string) => {
    switch (speaker.toLowerCase()) {
      case "webby":
        return "var(--mantine-color-yellow-3)";
      case "bloo":
        return "var(--mantine-color-blue-3)";
      default:
        return "var(--mantine-color-gray-3)";
    }
  };

  return (
    <Paper
      shadow="md"
      radius="md"
      p="md"
      bg={
        currentMessage?.speaker
          ? getBubbleColor(currentMessage.speaker)
          : "gray.1"
      }
      style={{
        borderColor: getBubbleBorderColor(currentMessage.speaker),
      }}
      withBorder
    >
      <Text size="lg" lh="relaxed" fw={300} mih="3rem" m={0} c="gray.7">
        {currentMessage?.speaker && (
          <Text
            span
            fw={600}
            c={
              currentMessage.speaker.toLowerCase() === "webby"
                ? "yellow.7"
                : "blue.7"
            }
          >
            {currentMessage.speaker}:
          </Text>
        )}{" "}
        {displayedText || " "}
      </Text>
    </Paper>
  );
}
