import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { PostHogProvider } from "posthog-js/react";

import { AuthProvider } from "./contexts/AuthContext";
import ConfigDashboard from "./components/ConfigDashboard";
import FirebaseRoute from "./components/FirebaseRoute";
import GoogleSignup from "./components/GoogleSignup";
import { Outlet } from "react-router-dom";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Signup from "./components/Signup";
import TeacherSignup from "./components/TeacherSignup";
import TeacherDashboard from "./components/Teacher/pages/HomePage";
import RoleBasedRoute from "./components/RoleBasedRoute";

import UploadLessonPlans from "./components/LessonConfigDashboard";
import CoachConfigDashboard from "./components/CoachConfigDashboard";
import featureFlags from "./utils/featureFlags";
import NotFoundPage from "./components/NotFoundPage";
import TeacherAccountConfigurationPage from "./components/Teacher/pages/AccountConfigurationPage";
import LessonBuilderPage from "./components/Teacher/pages/LessonBuilder/LessonBuilderPage";
import LessonBuilderLessonPage from "./components/Teacher/pages/LessonBuilderLesson/LessonBuilderLessonPage";
import LessonBuilderLessonListPage from "./components/Teacher/pages/LessonBuilder/LessonBuilderLessons";
import UnpackerLessonsPage from "./components/Unpacker/pages/UnpackerLessons";
import UnpackerLessonPage from "./components/Unpacker/pages/UnpackerLesson/UnpackerLesson";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CoachProvider } from "./contexts/CoachProvider";
import { ClientID } from "./utils/sessionUtils";
import NisaMantineTheme from "./config/mantine/theme";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "./App.css";
import ToolboxPage from "./components/Toolbox/ToolboxPage";
import LessonBuilderLandingPage from "./components/LessonBuilder/pages/LessonBuilderLandingPage";
import PodcastsPage from "./components/Podcasts/pages/PodcastsPage";

const queryClient = new QueryClient();

// Ensure Coach Routes are wrapped in Coach Provider
const CoachRoutes = () => {
  return (
    <CoachProvider>
      <Outlet />
    </CoachProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      {/* Public Routes */}
      <Route path="/signup" element={<Signup />} />
      {featureFlags.enableTeacherRoutes && (
        <Route path="/signup/teacher" element={<TeacherSignup />} />
      )}
      <Route path="/login" element={<Login />} />
      <Route path="/toolbox" element={<ToolboxPage />} />
      <Route path="/lesson-builder" element={<LessonBuilderLandingPage />} />
      <Route path="/lesson-builder/:id" element={<LessonBuilderLessonPage />} />
      <Route path="/podcasts" element={<PodcastsPage />} />

      {/* Firebase routes, require firebase token but no api authorization */}
      <Route element={<FirebaseRoute />}>
        <Route path="/google_signup" element={<GoogleSignup />} />
      </Route>
      {/* Teacher Private Routes, requiring authentication + api authorization */}
      {featureFlags.enableTeacherRoutes && (
        <Route element={<RoleBasedRoute allowedRoles={[ClientID.TEACHER]} />}>
          <Route path="/teacher" element={<TeacherDashboard />} />
          <Route
            path="/teacher/account"
            element={<TeacherAccountConfigurationPage />}
          />
          <Route
            path="/teacher/lesson_builder"
            element={<LessonBuilderPage />}
          />
          <Route
            path="/teacher/lesson_builder/:id"
            element={<LessonBuilderLessonPage />}
          />
          <Route
            path="/teacher/lesson_builder/lessons"
            element={<LessonBuilderLessonListPage />}
          />
        </Route>
      )}
      {featureFlags.enableUnpackerRoutes && (
        <>
          <Route path="/unpacker" element={<UnpackerLessonsPage />} />
          <Route path="/unpacker/lesson/:id" element={<UnpackerLessonPage />} />
        </>
      )}
      <Route element={<RoleBasedRoute allowedRoles={[ClientID.COACH]} />}>
        <Route element={<CoachRoutes />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/teacher_config" element={<ConfigDashboard />} />
          <Route path="/coach/config" element={<CoachConfigDashboard />} />
          <Route path="/lesson_config" element={<UploadLessonPlans />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
);

function App() {
  const AppContent = (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={NisaMantineTheme}>
          <Notifications />
          <RouterProvider router={router} />
        </MantineProvider>
      </QueryClientProvider>
    </AuthProvider>
  );

  if (process.env.REACT_APP_POSTHOG_KEY) {
    return (
      <PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_POSTHOG_HOST,
        }}
      >
        {AppContent}
      </PostHogProvider>
    );
  }

  return AppContent;
}

export default App;
