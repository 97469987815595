// Define the type for the feature flags
interface FeatureFlags {
  enableTeacherRoutes: boolean;
  enableUnpackerRoutes: boolean;
}

// Parse the environment variables and export the feature flags
const featureFlags: FeatureFlags = {
  enableTeacherRoutes: process.env.REACT_APP_ENABLE_TEACHER_ROUTES === "true",
  enableUnpackerRoutes: process.env.REACT_APP_ENABLE_UNPACKER_ROUTES === "true",
};

export default featureFlags;
