"use client";

import { useEffect, useRef, useState } from "react";
import { Editor, TLPageId, TLShapeId, Tldraw, track, useEditor } from "tldraw";
import "tldraw/tldraw.css";
import {
  continueUnpackerSession,
  fetchUnpackerSession,
  getLesson,
} from "../../../../api/nisaAPI";
import {
  UnpackerNextResponseIn,
  UnpackerNextResponseOut,
} from "@/api/nisaAPITypes";
import TranscriptViewer from "./TranscriptViewer";
import ControlBar from "./ControlBar";
import QuestionModal from "./QuestionModal";
import SummaryModal from "./SummaryModal";
import CheatSheetModal from "./CheatSheetModal";
import { useNavigate, useParams } from "react-router-dom";
import ReactCanvasConfetti from "react-canvas-confetti";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Button,
  Container,
  Group,
  Image,
  Loader,
  Modal,
  Paper,
  Stack,
  Text,
  Title,
  Transition,
  TypographyStylesProvider,
} from "@mantine/core";
import PulseIndicator from "../../../ui/PulseIndicator/PulseIndicator";
import { useAuth } from "../../../../contexts/AuthContext";
import classes from "./UnpackerLesson.module.css";
import { UnpackerLayout } from "../UnpackerLayout";
import { NisaFooterRef } from "@/components/ui/Footer";

// Declare global window interface
declare global {
  interface Window {
    latestTldrawEditor?: Editor;
  }
}

// Add interface for page info
interface PageInfo {
  id: TLPageId;
  name: string;
  createdAt: number;
}

const defaultConfettiOptions = {
  origin: { y: 0.7 },
  spread: 360,
  ticks: 100,
  gravity: 0,
  decay: 0.94,
  startVelocity: 30,
  colors: ["#FFD700", "#FFA500", "#FF6B6B", "#4CAF50", "#64B5F6"],
};

const CustomToolbar = track(() => {
  const editor = useEditor();
  const currentTool = editor.getCurrentToolId();

  return (
    <Paper
      shadow="sm"
      p="xs"
      radius="md"
      style={{
        position: "absolute",
        top: "1rem",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        backdropFilter: "blur(4px)",
        zIndex: 999,
      }}
      withBorder
    >
      <Group gap="xs">
        <Button
          onClick={() => editor.setCurrentTool("select")}
          variant={currentTool === "select" ? "light" : "subtle"}
          color={currentTool === "select" ? "blue" : "gray"}
          size="sm"
        >
          Select
        </Button>
        <Button
          onClick={() => editor.setCurrentTool("draw")}
          variant={currentTool === "draw" ? "light" : "subtle"}
          color={currentTool === "draw" ? "blue" : "gray"}
          size="sm"
        >
          Draw
        </Button>
        <Button
          onClick={() => editor.setCurrentTool("text")}
          variant={currentTool === "text" ? "light" : "subtle"}
          color={currentTool === "text" ? "blue" : "gray"}
          size="sm"
        >
          Text
        </Button>
        <Button
          onClick={() => editor.setCurrentTool("eraser")}
          variant={currentTool === "eraser" ? "light" : "subtle"}
          color={currentTool === "eraser" ? "blue" : "gray"}
          size="sm"
        >
          Eraser
        </Button>
      </Group>
    </Paper>
  );
});

export default function LessonPage() {
  const params = useParams();
  const sessionId = params.id!;

  const [error, setError] = useState<string | null>(null);
  const [showWhiteboard, setShowWhiteboard] = useState(false);
  const [messages, setMessages] = useState<UnpackerNextResponseOut[]>([]);
  const [waitingForUser, setWaitingForUser] = useState(false);
  const [highlightedText, setHighlightedText] = useState<string | null>(null);
  const [pages, setPages] = useState<PageInfo[]>([]);
  const [currentPageId, setCurrentPageId] = useState<TLPageId | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const hasStartedConversation = useRef(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [isHandRaised, setIsHandRaised] = useState(false);
  const pendingQuestionRef = useRef(false);
  const handRaiseRef = useRef(false);
  const [isThinkingAloud, setIsThinkingAloud] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [loadingStep, setLoadingStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [showCheatSheetModal, setShowCheatSheetModal] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [isGeneratingEndContent, setIsGeneratingEndContent] = useState(false);
  const [endContentError, setEndContentError] = useState<string | null>(null);
  const [showSummaryButtons, setShowSummaryButtons] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [hasUserDrawn, setHasUserDrawn] = useState(false);
  const [currentStage, setCurrentStage] = useState<
    "overview" | "analysis" | "framing" | null
  >(null);
  const { currentUser } = useAuth();
  const initialShapesRef = useRef<Set<string>>(new Set());
  const footerRef = useRef<NisaFooterRef>(null);
  const confettiInstanceRef = useRef<
    | Parameters<
        NonNullable<Parameters<typeof ReactCanvasConfetti>[0]["onInit"]>
      >[0]["confetti"]
    | undefined
  >();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const loadingMessages = [
    "Unleashing the lesson gremlins...",
    "Scrying teacher thoughts...",
    "Stirring pedagogical soup...",
    "Wrangling misconceptions...",
    "Untangling teaching spaghetti...",
    "Summoning the idea butterflies...",
  ];

  // Function to wrap text in highlight spans
  const wrapTextInHighlight = (
    text: string,
    textToHighlight: string,
  ): string => {
    if (!textToHighlight) return text;

    // Clean up the text to highlight (remove extra spaces, normalize quotes)
    const cleanTextToHighlight = textToHighlight
      .trim()
      .replace(/\s+/g, " ")
      .replace(/[""]/g, '"')
      .replace(/['']/g, "'");

    // Try to find the text with increasingly fuzzy matching
    let index = text.indexOf(cleanTextToHighlight);
    if (index === -1) {
      // Try case-insensitive
      index = text.toLowerCase().indexOf(cleanTextToHighlight.toLowerCase());
    }
    if (index === -1) {
      // Try with normalized whitespace
      const normalizedText = text.replace(/\s+/g, " ");
      index = normalizedText
        .toLowerCase()
        .indexOf(cleanTextToHighlight.toLowerCase());
      if (index !== -1) {
        // Adjust index to account for original text
        let adjustedIndex = 0;
        let normalizedIndex = 0;
        while (normalizedIndex < index) {
          if (
            !/\s/.test(text[adjustedIndex]) ||
            (text[adjustedIndex] === " " && !/\s/.test(text[adjustedIndex + 1]))
          ) {
            normalizedIndex++;
          }
          adjustedIndex++;
        }
        index = adjustedIndex;
      }
    }

    if (index === -1) return text;

    // Find the actual matched text from the original
    const matchLength =
      text
        .substr(index)
        .toLowerCase()
        .indexOf(cleanTextToHighlight.toLowerCase()) +
      cleanTextToHighlight.length;
    const actualMatch = text.substr(index, matchLength);

    const before = text.substring(0, index);
    const after = text.substring(index + matchLength);

    return `${before}<span class="highlight-text bg-yellow-300 px-1 rounded transition-colors duration-300 ring-2 ring-yellow-500">${actualMatch}</span>${after}`;
  };

  // Effect to scroll to highlight when it changes
  useEffect(() => {
    if (highlightedText) {
      const scrollToHighlight = (attemptsLeft: number) => {
        const highlights = Array.from(
          document.querySelectorAll(".highlight-text"),
        );
        if (highlights.length > 0) {
          // Find the first visible highlight
          let targetHighlight = highlights[0];
          for (const highlight of highlights) {
            const rect = highlight.getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
              targetHighlight = highlight;
              break;
            }
          }

          // Calculate offset to account for fixed transcript height
          const transcriptHeight =
            document
              .querySelector(".transcript-container")
              ?.getBoundingClientRect().height || 0;
          const highlightRect = targetHighlight.getBoundingClientRect();
          const targetScroll =
            window.scrollY + highlightRect.top - transcriptHeight - 100; // More padding for better visibility

          window.scrollTo({
            top: targetScroll,
            behavior: "smooth",
          });
        } else if (attemptsLeft > 0) {
          // Retry with exponential backoff
          setTimeout(
            () => scrollToHighlight(attemptsLeft - 1),
            100 * (6 - attemptsLeft),
          );
        }
      };

      // Start scrolling attempt immediately
      scrollToHighlight(5);
    }
  }, [highlightedText, messages]); // Also trigger on messages change to handle repeated highlights

  const { data: session } = useQuery({
    queryKey: ["unpacker", "session", sessionId],
    queryFn: async () => {
      const token = await currentUser?.getIdToken();
      return await fetchUnpackerSession(sessionId, token);
    },
    refetchInterval: false,
  });

  const { data: lesson } = useQuery({
    queryKey: ["unpacker", "lesson", session?.lesson_id],
    queryFn: async () => {
      const token = await currentUser?.getIdToken();
      const lessonData = await getLesson(
        "illustrative_mathematics",
        session!.lesson_id,
        token,
        true,
      );
      if (!lessonData.lesson_details) {
        throw new Error("Lesson details are missing, can't unpack.");
      }
      return lessonData;
    },
    enabled: !!session,
    refetchInterval: false,
  });

  interface ContinueSessionPayload {
    nextResponse: UnpackerNextResponseIn;
    audioFile?: File;
  }

  const {
    mutateAsync: continueUnpackerSessionMutateAsync,
    isPending: isContinueUnpackerSessionPending,
  } = useMutation({
    mutationFn: async ({ nextResponse, audioFile }: ContinueSessionPayload) => {
      const token = await currentUser?.getIdToken();
      return await continueUnpackerSession(
        sessionId,
        nextResponse,
        token,
        audioFile,
      );
    },
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 5000),
  });

  useEffect(() => {
    if (isInitializing) {
      const interval = setInterval(() => {
        setLoadingStep((prev) => (prev + 1) % loadingMessages.length);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [isInitializing]);

  // Load lesson data and start conversation
  useEffect(() => {
    const startConversation = async () => {
      try {
        // Only start the conversation if it hasn't been started yet
        if (!hasStartedConversation.current) {
          hasStartedConversation.current = true;

          console.log("[DEBUG] Starting conversation...");

          const response = await continueUnpackerSessionMutateAsync({
            nextResponse: {
              message_type: "continue",
            },
          });

          console.log("[DEBUG] Got first LLM response:", response);

          setMessages([response]);
          await handleUnpackerResponse(response);

          setIsInitializing(false);
        }
      } catch (err) {
        console.error("[DEBUG] Error in loadLesson:", err);
        setError(err instanceof Error ? err.message : "Failed to load lesson");
      }
    };
    startConversation();
  }, [lesson]);

  // Add function to check for user activity
  const checkForUserActivity = (editor: Editor) => {
    const currentShapes = new Set(editor.getCurrentPageShapeIds());
    const initialShapes = initialShapesRef.current;

    // Check if user has added any new shapes
    const hasNewShapes = Array.from(currentShapes).some(
      (id) => !initialShapes.has(id.toString()),
    );
    // Check if user has modified any initial shapes
    const hasModifiedShapes = Array.from(initialShapes).some((id) => {
      const shape = editor.getShape(id as TLShapeId);
      return !shape; // Shape was deleted or modified
    });

    setHasUserDrawn(hasNewShapes || hasModifiedShapes);
  };

  // Handle LLM response actions
  const handleUnpackerResponse = async (response: UnpackerNextResponseOut) => {
    // Update messages first so we have the latest state
    setMessages((prev) => [...prev, response]);

    // Update current stage if provided
    console.log("[DEBUG] Full LLM response:", JSON.stringify(response));
    console.log("[DEBUG] Current stage before update:", currentStage);
    if (response.stage) {
      console.log("[DEBUG] Stage change detected:", response.stage);
      setCurrentStage(response.stage);
    } else {
      console.log("[DEBUG] No stage provided in response");
    }

    // If this is a finished_lesson response, handle end content and return early
    if (response.finished_lesson) {
      console.log("Received finished_lesson response, handling end content");
      setIsGeneratingEndContent(true);

      // Play audio if available
      if (response.audio_url) {
        console.log("Playing final audio message");
        const audio = new Audio(response.audio_url);
        audioRef.current = audio;
        setIsPlaying(true);

        audio.addEventListener("ended", async () => {
          console.log("Final audio ended");
          setIsPlaying(false);
          audioRef.current = null;

          try {
            // Force a refresh of the lesson data (including cheat sheet and summary)
            queryClient.invalidateQueries({
              queryKey: ["unpacker", "session", sessionId],
            });
            setIsGeneratingEndContent(false);
            setShowSummaryButtons(true); // Show the buttons
            triggerConfetti();
          } catch (error) {
            console.error("Error fetching end content:", error);
            setEndContentError(
              "Failed to load summary and cheat sheet. Please try again.",
            );
            setIsGeneratingEndContent(false);
          }
        });

        audio.play().catch((error) => {
          console.error("Error playing final audio:", error);
          setIsPlaying(false);
          audioRef.current = null;
        });
      } else {
        // No audio case - try to fetch content immediately
        try {
          // Force a refresh of the lesson data (including cheat sheet and summary)
          queryClient.invalidateQueries({
            queryKey: ["unpacker", "session", sessionId],
          });
          setIsGeneratingEndContent(false);
          setShowSummaryButtons(true); // Show the buttons
        } catch (error) {
          console.error("Error fetching end content:", error);
          setEndContentError(
            "Failed to load summary and cheat sheet. Please try again.",
          );
          setIsGeneratingEndContent(false);
        }
      }

      setShowSurvey(true);

      return;
    }

    // Handle text highlighting
    if (response.highlight_text && response.text_to_highlight) {
      const textToHighlight = response.text_to_highlight.trim();
      setHighlightedText(textToHighlight);

      // Force re-highlight by clearing and setting again after a short delay
      setTimeout(() => {
        setHighlightedText(null);
        setTimeout(() => setHighlightedText(textToHighlight), 50);
      }, 50);
    } else {
      setHighlightedText(null);
    }

    // Handle whiteboard visibility and data
    if (response.whiteboard_visible) {
      setShowWhiteboard(true);
      const editor = window.latestTldrawEditor;
      if (editor && response.whiteboard_data) {
        // Create a new page for this whiteboard data
        const pageName = response.whiteboard_data
          .split(" ")
          .slice(0, 3)
          .join(" ");
        const pageId: TLPageId = `page:${Date.now()}` as TLPageId;

        editor.createPage({
          name: pageName,
          id: pageId,
        });

        editor.setCurrentPage(pageId);

        editor.createShape({
          type: "text",
          x: window.innerWidth / 4, // Center horizontally
          y: 100, // Give some top padding
          props: {
            text: response.whiteboard_data,
            color: "black",
            size: "l",
            font: "draw",
            textAlign: "start",
            scale: 1,
            autoSize: false,
            w: 800,
          },
        });

        editor.zoomToFit();

        // Reset user activity tracking for new page
        setHasUserDrawn(false);
        initialShapesRef.current = new Set(editor.getCurrentPageShapeIds());

        // Set up change listener for this page
        editor.on("change", () => {
          checkForUserActivity(editor);
        });

        setPages((prev) => [
          ...prev,
          { id: pageId, name: pageName, createdAt: Date.now() },
        ]);
        setCurrentPageId(pageId);
      }
    } else {
      setShowWhiteboard(false);
    }

    // Update wait state
    setWaitingForUser(response.wait_for_user ?? false);

    // Handle audio if available
    if (response.audio_url) {
      console.log("Creating new audio element for URL:", response.audio_url);
      const audio = new Audio(response.audio_url);
      audioRef.current = audio;
      setIsPlaying(true);

      audio.addEventListener("ended", async () => {
        console.log("Audio ended, hand raised:", handRaiseRef.current);
        setIsPlaying(false);
        audioRef.current = null;

        // First priority: Check hand raise
        if (handRaiseRef.current) {
          console.log("Opening question modal due to hand raise");
          setShowQuestionModal(true);
        }
        // Second priority: Check for wait_for_user
        else if (response.wait_for_user) {
          console.log("Waiting for user input");
          // Do nothing, wait for user input
        }
        // Last priority: Continue conversation if not finished
        else if (!messages[messages.length - 1]?.finished_lesson) {
          console.log("Continuing conversation");
          await handleContinue();
        }
      });

      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
        setIsPlaying(false);
        audioRef.current = null;
        setIsHandRaised(false);
        handRaiseRef.current = false;
        pendingQuestionRef.current = false;
      });
    } else {
      // No audio case - follow same priority logic
      const handleNoAudio = async () => {
        // Don't continue if the lesson is finished
        if (messages[messages.length - 1]?.finished_lesson) {
          console.log("Lesson is already finished, not continuing");
          return;
        }

        if (handRaiseRef.current) {
          console.log("No audio, opening question modal due to hand raise");
          setShowQuestionModal(true);
        } else if (response.wait_for_user) {
          console.log("No audio, waiting for user input");
          // Do nothing, wait for user input
        } else {
          console.log("No audio, continuing conversation");
          await handleContinue();
        }
      };

      // Call the async function
      handleNoAudio();
    }
  };

  // Handle user questions
  const handleQuestion = async (question: string, audioBlob?: Blob) => {
    try {
      // Reset hand raise state
      setIsHandRaised(false);
      handRaiseRef.current = false;
      pendingQuestionRef.current = false;

      // Stop current audio if playing
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        setIsPlaying(false);
      }

      // Create request object
      const request: UnpackerNextResponseIn = {
        message_type: "question",
        message: question,
      };

      let audioFile: File | undefined;
      // If we have an audio blob, convert it to a File object
      if (audioBlob) {
        audioFile = new File([audioBlob], "question.webm", {
          type: "audio/webm",
        });
      }

      const response = await continueUnpackerSessionMutateAsync({
        nextResponse: request,
        audioFile,
      });

      // Add the response and handle it without replacing history
      setMessages((prev) => [...prev, response]);
      await handleUnpackerResponse(response);
    } catch (err) {
      setError("Failed to get response");
      setIsHandRaised(false);
      handRaiseRef.current = false;
      pendingQuestionRef.current = false;
      throw err; // Re-throw to let QuestionModal handle the error state
    }
  };

  // Handle user answers
  const handleAnswer = async (answer: string) => {
    if (!waitingForUser) return;

    try {
      // Parse the answer to check if it contains thoughts
      let message = answer;
      try {
        const parsedAnswer = JSON.parse(answer);
        if (parsedAnswer.thoughts) {
          // If we have thoughts, format them nicely in the message
          message = JSON.stringify({
            ...parsedAnswer,
            thoughts: `Student's thoughts while solving: "${parsedAnswer.thoughts}"`,
          });
        }
      } catch {
        // If parsing fails, use the answer as is
        console.log("Not a JSON answer, using as is");
      }

      const response = await continueUnpackerSessionMutateAsync({
        nextResponse: {
          message_type: "answer",
          message: message,
        },
      });
      setMessages((prev) => [...prev, response]);
      await handleUnpackerResponse(response);
    } catch {
      setError("Failed to get response");
    }
  };

  // Handle continue
  const handleContinue = async () => {
    try {
      // Get the last message
      const lastMessage = messages[messages.length - 1];

      // If the last message was a finished_lesson message, don't continue
      if (lastMessage?.finished_lesson) {
        console.log("Lesson is already finished, not continuing");
        return;
      }

      const response = await continueUnpackerSessionMutateAsync({
        nextResponse: {
          message_type: "continue",
        },
      });
      await handleUnpackerResponse(response);
    } catch {
      setError("Failed to get response");
    }
  };

  // Handle pause/resume
  const togglePause = () => {
    if (audioRef.current) {
      if (isPaused) {
        audioRef.current.play();
        setIsPaused(false);
      } else {
        audioRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  // Clean up audio on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // Handle raising hand
  const handleRaiseHand = () => {
    const newHandRaised = !isHandRaised;
    console.log("Hand raise toggled to:", newHandRaised);

    // Update both state and ref immediately
    setIsHandRaised(newHandRaised);
    handRaiseRef.current = newHandRaised;
    pendingQuestionRef.current = newHandRaised;

    if (newHandRaised && !isPlaying) {
      // If no audio is playing, show question modal immediately
      setShowQuestionModal(true);
    } else if (!newHandRaised) {
      console.log("Hand lowered");
      // If audio isn't playing, continue the conversation
      if (!isPlaying && !waitingForUser) {
        handleContinue();
      }
    } else {
      console.log(
        "Hand raised, will show question modal when current audio ends",
      );
    }
  };

  // Clean up function to handle state reset
  const cleanupStates = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    setIsPlaying(false);
    setIsHandRaised(false);
    handRaiseRef.current = false;
    pendingQuestionRef.current = false;
  };

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupStates();
    };
  }, []);

  const startWhiteboardRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data);
        }
      };

      mediaRecorder.start(1000);
      setIsThinkingAloud(true);
    } catch (error) {
      console.error("Error starting recording:", error);
      setError("Could not access microphone. Please check your permissions.");
    }
  };

  const stopWhiteboardRecording = async () => {
    if (mediaRecorderRef.current && isThinkingAloud) {
      mediaRecorderRef.current.stop();
      const stream = mediaRecorderRef.current.stream;
      stream.getTracks().forEach((track) => track.stop());
      setIsThinkingAloud(false);
      setIsSubmitting(true);

      // Create audio blob and submit with whiteboard data
      const audioBlob = new Blob(audioChunksRef.current, {
        type: "audio/webm",
      });
      const editor = window.latestTldrawEditor;
      if (!editor) {
        setIsSubmitting(false);
        return;
      }

      try {
        const snapshot = editor.store.getSnapshot();
        const audioFile = new File([audioBlob], "answer.webm", {
          type: "audio/webm",
        });

        const response = await continueUnpackerSessionMutateAsync({
          nextResponse: {
            message_type: "answer",
            message: JSON.stringify({
              type: "whiteboard_snapshot",
              snapshot: snapshot,
              shapes: Array.from(editor.getCurrentPageShapeIds())
                .map((id) => {
                  const shape = editor.getShape(id);
                  return shape
                    ? {
                        type: shape.type,
                        props: shape.props,
                        x: shape.x,
                        y: shape.y,
                      }
                    : null;
                })
                .filter(Boolean),
            }),
          },
          audioFile: audioFile,
        });

        // Handle the response the same way as other LLM responses
        setMessages((prev) => [...prev, response]);
        await handleUnpackerResponse(response);
      } catch (error) {
        console.error("Error submitting whiteboard with audio:", error);
        setError("Failed to submit answer. Please try again.");
        // Reset thinking aloud state on error
        setIsThinkingAloud(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Clean up audio recording on unmount
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && isThinkingAloud) {
        const stream = mediaRecorderRef.current.stream;
        mediaRecorderRef.current.stop();
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isThinkingAloud]);

  // Add function to trigger confetti
  const triggerConfetti = () => {
    // Fire multiple bursts of confetti
    const fire = (
      particleRatio: number,
      opts: Parameters<NonNullable<typeof confettiInstanceRef.current>>[0],
    ) => {
      confettiInstanceRef.current?.({
        ...defaultConfettiOptions,
        ...opts,
        particleCount: Math.floor(200 * particleRatio),
      });
    };

    // Stagger multiple confetti bursts for a more dramatic effect
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    fire(0.2, {
      spread: 60,
    });

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  if (isInitializing) {
    return (
      <UnpackerLayout footerRef={footerRef}>
        <Paper p="lg" shadow="md" radius="md" maw={400} w="100%">
          <Stack align="center" gap="xl">
            <Box mb="xl">
              <Box mb="lg" w={80} h={80} mx="auto">
                <Image
                  src="/open-box.png"
                  alt="Loading"
                  style={{ animation: "bounce 1s infinite" }}
                />
              </Box>
              <Box h={64}>
                <Text
                  size="xl"
                  fw={300}
                  style={{
                    color: "#333",
                    transition: "opacity 0.5s",
                  }}
                >
                  {loadingMessages[loadingStep]}
                </Text>
              </Box>
            </Box>
            <Stack gap="sm" w="100%">
              {Array.from({ length: 3 }).map((_, i) => (
                <Box
                  key={i}
                  h={8}
                  bg="yellow.3"
                  style={(theme) => ({
                    borderRadius: theme.radius.xl,
                    overflow: "hidden",
                  })}
                >
                  <PulseIndicator color="yellow" />
                </Box>
              ))}
            </Stack>
          </Stack>
        </Paper>
      </UnpackerLayout>
    );
  }

  if (error) return <div>Error: {error}</div>;
  if (!lesson) return <div>No lesson found</div>;

  // Helper function to process HTML content
  const processContent = (html: string): string => {
    if (!highlightedText) return html;

    // Use DOMParser to parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Helper function to check if two strings are similar
    const areSimilar = (str1: string, str2: string): boolean => {
      const s1 = str1.toLowerCase().trim().replace(/\s+/g, " ");
      const s2 = str2.toLowerCase().trim().replace(/\s+/g, " ");

      // Check for exact match after normalization
      if (s1 === s2) return true;

      // Only check if the longer string contains the shorter one
      const [shorter, longer] = s1.length < s2.length ? [s1, s2] : [s2, s1];
      if (longer.includes(shorter) && shorter.length > 3) return true; // Only match if substring is meaningful

      return false;
    };

    // Function to process text nodes and element content
    const processNode = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent || "";
        if (text.toLowerCase().includes(highlightedText.toLowerCase())) {
          const span = document.createElement("span");
          span.innerHTML = wrapTextInHighlight(text, highlightedText);
          node.parentNode?.replaceChild(span, node);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        const text = element.textContent || "";

        // Use fuzzy matching for elements
        if (areSimilar(text, highlightedText)) {
          element.innerHTML = wrapTextInHighlight(text, highlightedText);
          return; // Skip processing children
        }

        // For no matches, process child nodes
        node.childNodes.forEach(processNode);
      }
    };

    // Process the body content
    processNode(doc.body);

    return doc.body.innerHTML;
  };

  return (
    <>
      {/* Question Modal - Single instance at root level */}
      <QuestionModal
        isOpen={showQuestionModal}
        onClose={() => setShowQuestionModal(false)}
        onSubmit={handleQuestion}
        onCancel={() => {
          setIsHandRaised(false);
          handRaiseRef.current = false;
          pendingQuestionRef.current = false;
          if (!isPlaying && !waitingForUser) {
            handleContinue();
          }
        }}
      />

      {/* Confetti */}
      <ReactCanvasConfetti
        onInit={({ confetti }) => {
          confettiInstanceRef.current = confetti;
        }}
      />

      {/* Whiteboard */}
      <Box
        pos="fixed"
        bg="white"
        right={0}
        top={0}
        w="50%"
        h="100vh"
        style={{
          borderLeft: "1px solid var(--mantine-color-gray-3)",
          transform: showWhiteboard ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.5s ease-in-out",
          zIndex: 30,
        }}
      >
        {/* Page list */}
        {pages.length > 0 && (
          <Paper
            py="xs"
            px="md"
            bg="gray.0"
            style={{
              borderBottom: "1px solid var(--mantine-color-gray-3)",
              overflowX: "auto",
            }}
          >
            <Group gap="xs" wrap="nowrap">
              {pages.map((page) => (
                <Button
                  key={page.id}
                  onClick={() => {
                    const editor = window.latestTldrawEditor;
                    if (editor) {
                      editor.setCurrentPage(page.id);
                      setCurrentPageId(page.id);
                    }
                  }}
                  variant={currentPageId === page.id ? "light" : "subtle"}
                  color={currentPageId === page.id ? "blue" : "gray"}
                  size="sm"
                  style={{ whiteSpace: "nowrap" }}
                  flex="1 0 auto"
                >
                  {page.name}
                </Button>
              ))}
            </Group>
          </Paper>
        )}

        <Box pos="relative" flex="1" h="100%">
          {/* Tldraw container with bottom padding for buttons */}
          <Box pos="absolute" inset={0} pb={69}>
            <Box w="100%" h="100%">
              <Tldraw
                onMount={(editor) => {
                  window.latestTldrawEditor = editor;

                  // Set text as the default tool
                  editor.setCurrentTool("text");

                  if (messages[0]?.whiteboard_data) {
                    const pageId = `page:${Date.now()}` as TLPageId;
                    const pageName = messages[0].whiteboard_data
                      .split(" ")
                      .slice(0, 3)
                      .join(" ");

                    editor.createPage({
                      name: pageName,
                      id: pageId,
                    });

                    editor.setCurrentPage(pageId);

                    editor.createShape({
                      type: "text",
                      x: window.innerWidth / 4, // Center horizontally
                      y: 100, // Give some top padding
                      props: {
                        text: messages[0].whiteboard_data,
                        color: "black",
                        size: "l",
                        font: "draw",
                        textAlign: "start",
                        scale: 1,
                        autoSize: false,
                        w: 800,
                      },
                    });

                    editor.zoomToFit();

                    setPages([
                      {
                        id: pageId,
                        name: pageName,
                        createdAt: Date.now(),
                      },
                    ]);
                    setCurrentPageId(pageId);
                  }
                }}
                hideUi={true}
              >
                <CustomToolbar />
              </Tldraw>
            </Box>
          </Box>

          {/* Whiteboard controls with higher z-index and semi-transparent background */}
          {waitingForUser && (
            <Paper
              pos="fixed"
              bottom={0}
              left={0}
              right={0}
              p="md"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.95)",
                backdropFilter: "blur(4px)",
                zIndex: 9999,
                borderTop: "1px solid var(--mantine-color-gray-3)",
              }}
              shadow="sm"
            >
              <Group justify="center" gap="md">
                {isSubmitting ? (
                  <Stack align="center" py="xs">
                    <Loader size="lg" color="blue" />
                    <Text c="dimmed">Processing your response...</Text>
                  </Stack>
                ) : !isThinkingAloud ? (
                  <>
                    {hasUserDrawn && (
                      <Button
                        onClick={async () => {
                          const editor = window.latestTldrawEditor;
                          if (!editor) return;

                          setIsSubmitting(true);
                          try {
                            const snapshot = editor.store.getSnapshot();
                            await handleAnswer(
                              JSON.stringify({
                                type: "whiteboard_snapshot",
                                snapshot: snapshot,
                                shapes: Array.from(
                                  editor.getCurrentPageShapeIds(),
                                )
                                  .map((id) => {
                                    const shape = editor.getShape(id);
                                    return shape
                                      ? {
                                          type: shape.type,
                                          props: shape.props,
                                          x: shape.x,
                                          y: shape.y,
                                        }
                                      : null;
                                  })
                                  .filter(Boolean),
                              }),
                            );
                          } catch (error) {
                            console.error(
                              "Error submitting whiteboard:",
                              error,
                            );
                            setError(
                              "Failed to submit answer. Please try again.",
                            );
                          } finally {
                            setIsSubmitting(false);
                          }
                        }}
                        color="green"
                        leftSection={<span>✓</span>}
                      >
                        Submit Answer
                      </Button>
                    )}
                    <Button
                      onClick={startWhiteboardRecording}
                      variant="light"
                      color="gray"
                      leftSection={<span>🎤</span>}
                    >
                      Think Aloud
                    </Button>
                  </>
                ) : (
                  <Group>
                    <Group gap="xs">
                      <Box
                        w={8}
                        h={8}
                        style={{
                          backgroundColor: "var(--mantine-color-red-6)",
                          borderRadius: "var(--mantine-radius-sm)",
                          animation: "pulse 2s infinite",
                        }}
                      />
                      <Text c="dimmed">Recording in progress...</Text>
                    </Group>
                    <Button
                      onClick={stopWhiteboardRecording}
                      color="red"
                      leftSection={<span>■</span>}
                    >
                      Stop & Submit
                    </Button>
                  </Group>
                )}
              </Group>
            </Paper>
          )}
        </Box>
      </Box>

      {/* Main content with background */}
      <UnpackerLayout footerRef={footerRef}>
        {/* Lesson content */}
        <Box
          style={{
            width: showWhiteboard ? "50%" : "100%",
            minHeight: "100vh",
            overflowY: "auto",
            position: "relative",
            alignSelf: "flex-start",
          }}
          pb={128}
        >
          {/* Spacer for transcript */}
          <Box h={128} />

          <Box p="lg" ref={contentRef}>
            <Paper shadow="lg" radius="lg" p="lg" mx="auto" maw={1024}>
              <Stack>
                <Title order={1} fw={900}>
                  {lesson.lesson_details!.title}
                </Title>

                {lesson.lesson_details!.learning_goals && (
                  <Paper
                    bg="blue.0"
                    radius="md"
                    p="lg"
                    withBorder
                    shadow="none"
                    style={{
                      borderColor: "var(--mantine-color-blue-3)",
                    }}
                  >
                    <Title order={2} c="blue.9" fw={200} mb="md">
                      Learning Goals
                    </Title>
                    <TypographyStylesProvider
                      c="blue.8"
                      fw={300}
                      className={classes.prose}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(
                            lesson.lesson_details!.learning_goals,
                          ),
                        }}
                      />
                    </TypographyStylesProvider>
                  </Paper>
                )}

                {session && session.summary && (
                  <Box
                    bg="gray.0"
                    p="lg"
                    style={{
                      borderColor: "var(--mantine-color-gray-3)",
                      borderRadius: "var(--mantine-border-radius-md)",
                    }}
                  >
                    <Title order={2} c="gray.9" fw={200} mb="md">
                      Lesson Summary
                    </Title>
                    <TypographyStylesProvider
                      c="gray.8"
                      fw={300}
                      className={classes.prose}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(session!.summary.summary),
                        }}
                      />
                    </TypographyStylesProvider>
                  </Box>
                )}

                {lesson.lesson_details!.narrative && (
                  <Box p="lg">
                    <Title order={2} fw={200} mb="md">
                      Narrative
                    </Title>
                    <TypographyStylesProvider
                      fw={300}
                      className={classes.prose}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(
                            lesson.lesson_details!.narrative,
                          ),
                        }}
                      />
                    </TypographyStylesProvider>
                  </Box>
                )}
                {lesson.lesson_details!.activities.length > 0 && (
                  <Box p="lg">
                    <Title order={2} fw={200} mb="md">
                      Activities
                    </Title>
                    <Stack>
                      {lesson.lesson_details!.activities.map(
                        (activity, index) => (
                          <Paper key={index} radius="md" p="lg" withBorder>
                            <Stack>
                              <Title order={4} c="gray.9">
                                {activity.title}
                              </Title>
                              <TypographyStylesProvider
                                c="gray.9"
                                fw={300}
                                className={classes.prose}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: processContent(activity.narrative),
                                  }}
                                />
                              </TypographyStylesProvider>
                              <Stack gap="md">
                                {activity.setup && (
                                  <Box>
                                    <Title order={5} c="gray.7" mb={8}>
                                      Setup
                                    </Title>
                                    <TypographyStylesProvider
                                      fw={300}
                                      className={classes.prose}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: processContent(
                                            activity.setup,
                                          ),
                                        }}
                                      />
                                    </TypographyStylesProvider>
                                  </Box>
                                )}

                                {activity.activity_synthesis && (
                                  <Box>
                                    <Title order={5} c="gray.7" mb={8}>
                                      Synthesis
                                    </Title>
                                    <TypographyStylesProvider
                                      fw={300}
                                      className={classes.prose}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: processContent(
                                            activity.activity_synthesis,
                                          ),
                                        }}
                                      />
                                    </TypographyStylesProvider>
                                  </Box>
                                )}
                              </Stack>

                              {activity.anticipated_misconceptions && (
                                <Box
                                  bg="yellow.0"
                                  p="md"
                                  style={(theme) => ({
                                    borderRadius: theme.radius.md,
                                  })}
                                >
                                  <Title order={5} c="yellow.9" mb={8}>
                                    Anticipated Misconceptions
                                  </Title>
                                  <TypographyStylesProvider
                                    c="yellow.7"
                                    fw={300}
                                    className={classes.prose}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: processContent(
                                          activity.anticipated_misconceptions,
                                        ),
                                      }}
                                    />
                                  </TypographyStylesProvider>
                                </Box>
                              )}
                            </Stack>
                          </Paper>
                        ),
                      )}
                    </Stack>
                  </Box>
                )}

                {lesson.lesson_details!.synthesis && (
                  <Paper
                    bg="green.0"
                    radius="md"
                    p="lg"
                    withBorder
                    shadow="none"
                    style={{
                      borderColor: "var(--mantine-color-green-3)",
                    }}
                  >
                    <Title order={2} c="green.9" fw={200} mb="md">
                      Learning Goals
                    </Title>
                    <TypographyStylesProvider
                      c="green.8"
                      fw={300}
                      className={classes.prose}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: processContent(
                            lesson.lesson_details!.synthesis,
                          ),
                        }}
                      />
                    </TypographyStylesProvider>
                  </Paper>
                )}
              </Stack>
            </Paper>
          </Box>
        </Box>
      </UnpackerLayout>

      {/* Floating elements */}
      {/* Transcript Viewer */}
      <Container
        pos="fixed"
        top={16}
        style={{
          zIndex: 30,
          maxWidth: "90%",
          width: "768px",
          left: showWhiteboard ? "25%" : "50%",
          transform: "translateX(-50%)",
          paddingLeft: showWhiteboard ? "2rem" : 0,
          transition:
            "left 0.5s ease, transform 0.5s ease, padding-left 0.5s ease",
        }}
      >
        <TranscriptViewer messages={messages} />
      </Container>

      {/* Summary Buttons */}
      <Transition
        mounted={showSummaryButtons}
        transition="slide-up"
        duration={500}
        timingFunction="ease"
      >
        {(styles) => (
          <Container
            pos="fixed"
            bottom={150}
            style={{
              ...styles,
              zIndex: 51,
              maxWidth: "90%",
              width: "768px",
              left: showWhiteboard ? "25%" : "50%",
              transform: "translateX(-50%)",
              paddingLeft: showWhiteboard ? "2rem" : 0,
              transition:
                "left 0.5s ease, transform 0.5s ease, padding-left 0.5s ease",
            }}
          >
            <Group justify="center" gap="md">
              <Button
                onClick={() => setShowSummaryModal(true)}
                color="blue"
                leftSection={<span>📝</span>}
              >
                Share Summary
              </Button>
              <Button
                onClick={() => setShowCheatSheetModal(true)}
                color="green"
                leftSection={<span>📚</span>}
              >
                View Cheat Sheet
              </Button>
              <Button
                onClick={() => navigate("/unpacker")}
                color="yellow"
                leftSection={
                  <Image src="/open-box.png" alt="unpacker" h={16} w={16} />
                }
              >
                Back to Unpacker
              </Button>
            </Group>
          </Container>
        )}
      </Transition>

      {/* Control Bar */}
      <Box
        pos="fixed"
        bottom={16}
        style={{
          zIndex: 50,
          width: "90%",
          maxWidth: "48rem",
          transition: "all 500ms ease 500ms",
          transform: "translateX(-50%)",
          left: showWhiteboard ? "25%" : "50%",
          paddingLeft: showWhiteboard ? "var(--mantine-spacing-lg)" : 0,
        }}
      >
        <ControlBar
          onRaiseHand={handleRaiseHand}
          onTogglePause={togglePause}
          onToggleWhiteboard={() => setShowWhiteboard(!showWhiteboard)}
          isPaused={isPaused}
          isWhiteboardVisible={showWhiteboard}
          isHandRaised={isHandRaised}
          isLoading={isContinueUnpackerSessionPending}
          isLessonFinished={
            messages[messages.length - 1]?.finished_lesson || false
          }
          currentStage={currentStage}
        />
      </Box>

      {/* Modals */}
      {session && session.summary && (
        <SummaryModal
          isOpen={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
          summary={session.summary}
        />
      )}

      {session && session.cheat_sheet && (
        <CheatSheetModal
          isOpen={showCheatSheetModal}
          onClose={() => setShowCheatSheetModal(false)}
          cheatSheet={session.cheat_sheet}
          session={session}
        />
      )}

      {/* PostHog Survey is configured to show when element with id "posthog-unpacker-survey" is rendered. */}
      {showSurvey && <div id="posthog-unpacker-survey" />}

      {/* Error and Loading States */}
      {endContentError && (
        <Transition
          mounted={!!endContentError}
          transition="slide-up"
          duration={400}
        >
          {(styles) => (
            <Paper
              style={(theme) => ({
                ...styles,
                position: "fixed",
                bottom: "6rem",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 50,
                borderColor: theme.colors.red[4],
              })}
              p="md"
              radius="md"
              bg="red.1"
              c="red.7"
              withBorder
            >
              <Text>{endContentError}</Text>
            </Paper>
          )}
        </Transition>
      )}

      <Modal
        opened={isGeneratingEndContent && !isPlaying}
        onClose={() => {}}
        withCloseButton={false}
        centered
        overlayProps={{
          color: "black",
          opacity: 0.5,
          blur: 3,
        }}
      >
        <Stack align="center" gap="md">
          <Loader size="lg" color="blue" />
          <Text c="dimmed" ta="center">
            Generating lesson summary and cheat sheet...
          </Text>
        </Stack>
      </Modal>
    </>
  );
}
