import {
  Box,
  BoxComponentProps,
  Container,
  Flex,
  FlexProps,
} from "@mantine/core";
import React from "react";

const breakpoint = "md";

export const SplitLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Flex
      direction="column"
      justify="flex-start"
      align="center"
      mih="100vh"
      gap="sm"
    >
      {children}
    </Flex>
  );
};

export const SplitLayoutFooter: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box
      w="100%"
      mt="auto"
      style={(theme) => ({ borderTop: `1px solid ${theme.colors.gray[1]}` })}
    >
      <Container size="xl">{children}</Container>
    </Box>
  );
};

export const SplitLayoutMain: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Flex
      direction={{ base: "column-reverse", [breakpoint]: "row" }}
      gap={0}
      align="center"
      w="100%"
    >
      {children}
    </Flex>
  );
};

export const SplitLayoutSection: React.FC<
  React.PropsWithChildren<FlexProps>
> = ({ children, ...props }) => {
  return (
    <Flex
      w={{ base: "100%", [breakpoint]: "50%" }}
      flex={{ base: "0 100%", [breakpoint]: "0 50%" }}
      align="center"
      justify="stretch"
      {...props}
    >
      {children}
    </Flex>
  );
};
