import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../ui/input";
import { Button } from "../../../ui/button";
import { useAuth } from "../../../../contexts/AuthContext";
import { Header } from "../../Header";
import { Alert } from "../../../../components/ui/alert";
import LessonSelector from "../../../../components/LessonSelector";
import { LessonOut, LessonSource } from "../../../../api/nisaAPITypes";
import { getOrCreateLessonBuilderLesson } from "../../../../api/nisaAPI";

export const lessonSourceOptions: LessonSource[] = [
  "illustrative_mathematics",
  "custom",
  "eureka_math",
];

const CreateLessonForm: React.FC = () => {
  // Local state for errors and form fields
  const [error, setError] = useState<string>("");
  // State for the lesson source selected by the user
  const [lessonSource, setLessonSource] = useState<LessonSource | "">("");
  // These fields will be set by the LessonSelector
  const [sourceId, setSourceId] = useState("");
  // Coach Action remains an optional field
  const [coachAction, setCoachAction] = useState("");

  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  // Logout handler
  const handleLogout = async () => {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  };

  // Mutation for creating a lesson
  const { mutateAsync: createLesson, isPending: isCreating } = useMutation({
    mutationFn: async (lessonData: {
      source_id: string;
      coach_action?: string;
    }): Promise<{ id: string }> => {
      const token = await currentUser.getIdToken();
      return await getOrCreateLessonBuilderLesson(lessonData, token);
    },
  });

  // Form submit handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const lesson = await createLesson({
        source_id: sourceId,
        coach_action: coachAction,
      });
      navigate(`/teacher/lesson_builder/${lesson.id}`);
    } catch (error) {
      console.error(error);
      setError("Failed to create lesson");
    }
  };

  // Callback to receive lesson details from LessonSelector.
  const handleLessonChange = (selectedLesson: LessonOut) => {
    setSourceId(selectedLesson.id);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      {/* Background styling */}
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      {/* Fixed Header */}
      <div className="fixed top-0 left-0 right-0 z-10">
        <Header onLogout={handleLogout} />
      </div>

      {/* Error Alert */}
      {error && (
        <div className="fixed top-16 z-20 w-full flex justify-center">
          <Alert message={error} className="max-w-lg" />
        </div>
      )}

      {/* Main content area */}
      <main
        className="container mx-auto p-4 flex-grow flex flex-col items-center"
        style={{ paddingTop: "75px" }}
      >
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-xl bg-gray-100 p-6 rounded-md shadow-md space-y-4"
        >
          {/* Lesson Source Dropdown */}
          <div>
            <label className="block mb-1 font-semibold">Lesson Source</label>
            <select
              name="lesson_source"
              value={lessonSource ?? ""}
              onChange={(e) => {
                setLessonSource(e.target.value as LessonSource);
                // Clear any existing lesson details when the source changes
                setSourceId("");
              }}
              className="w-full p-2 border rounded"
              required
            >
              <option value="" disabled>
                Select a lesson source
              </option>
              {lessonSourceOptions.map((source) => (
                <option key={source} value={source}>
                  {source.replace(/_/g, " ")}
                </option>
              ))}
            </select>
          </div>

          {/* Always render the LessonSelector if a lesson source has been chosen */}
          {lessonSource && (
            <LessonSelector
              lessonSource={lessonSource}
              onLessonChange={handleLessonChange}
            />
          )}

          {/* Coach Action Field */}
          <Input
            type="text"
            value={coachAction}
            onChange={(e) => setCoachAction(e.target.value)}
            placeholder="Coach Action (optional)"
            className="w-full p-2 border rounded"
          />

          <Button
            type="submit"
            className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            disabled={isCreating}
          >
            {isCreating ? "Creating..." : "Create Lesson"}
          </Button>
        </form>
      </main>
    </div>
  );
};

export default CreateLessonForm;
