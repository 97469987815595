import { Box, Container, Flex } from "@mantine/core";
import React, { forwardRef } from "react";

export const SimpleLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      mih="100vh"
      gap="sm"
    >
      {children}
    </Flex>
  );
};

export const SimpleLayoutFooter = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren
>(({ children }, ref) => {
  return (
    <Box
      ref={ref}
      w="100%"
      mt="auto"
      style={(theme) => ({ borderTop: `1px solid ${theme.colors.gray[1]}` })}
    >
      <Container size="xl">{children}</Container>
    </Box>
  );
});

SimpleLayoutFooter.displayName = "SimpleLayoutFooter";

export const SimpleLayoutMain: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <>{children}</>;
};
