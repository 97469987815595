"use client";

import { forwardRef, useImperativeHandle } from "react";
import {
  Anchor,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export interface NisaFooterRef {
  openModal: () => void;
}

const NisaFooter = forwardRef<NisaFooterRef>((props, ref) => {
  const [opened, { open, close }] = useDisclosure(false);

  useImperativeHandle(ref, () => ({
    openModal: open,
  }));

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title={<Title order={4}>What is NISA?</Title>}
        size="lg"
      >
        <Stack mt="md">
          <Text>
            NISA is an AI-powered educational platform providing teachers and
            instructional coaches the support they need to become{" "}
            <strong>experts at their craft</strong>
          </Text>
          <Text>
            It&apos;s an <strong>ecosystem</strong> that runs on the real
            insights generated by <strong>human coaches</strong> during
            classroom observations and coaching conversations.
          </Text>
          <Text>
            NISA <i>collaborates</i> with coaches to turn those insights into a
            full suite of supports for teachers - from experiences like unpacker
            to interactive lesson rehearsals, right-time text message support,
            custom podcasts, novel lesson planning tools and more.
          </Text>
          <Text>
            The result is a set of <strong>wrap-around supports</strong> that
            are <i>aware of and responsive to the unique, dynamic context</i> of
            each teacher&apos;s classroom.
          </Text>
          <Text mt="md" c="dimmed" ta="center">
            NISA is being developed by{" "}
            <Anchor
              href="https://linkedin.com/in/agasthyaps"
              target="_blank"
              rel="noopener noreferrer"
            >
              Agasthya Shenoy
            </Anchor>
            , an AI Fellow at{" "}
            <Anchor
              href="https://teachinglabstudio.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Teaching Lab Studio
            </Anchor>
            .
          </Text>
          <Text c="dimmed" ta="center">
            Want to stay in the loop?{" "}
            <Anchor
              href="https://forms.gle/wpTedLWeqrVZgupT6"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </Anchor>
            !
          </Text>
        </Stack>
      </Modal>

      <Group justify="space-between" py="md">
        <Anchor href="/" underline="never">
          <Text fw={700} size="xl">
            NISA
          </Text>
        </Anchor>
        <Group>
          <Text size="sm" c="dimmed">
            A Learning Experiment
          </Text>
          <Button variant="outline" onClick={open}>
            Learn More
          </Button>
        </Group>
      </Group>
    </>
  );
});

NisaFooter.displayName = "NisaFooter";

export default NisaFooter;
