import React from "react";
import PropTypes from "prop-types";

export const FormLayout = ({ children, title, subtitle, className }) => {
  return (
    <div
      className={`min-h-screen flex flex-col items-center justify-center relative ${className}`}
    >
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />
      <div className="relative z-10 w-full max-w-md">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-light text-white mb-2">{title}</h1>
          {subtitle && <h3 className="text-xl text-white">{subtitle}</h3>}
        </div>
        {children}
      </div>
    </div>
  );
};

FormLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};
