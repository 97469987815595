import { TeacherDataOut } from "../../../api/nisaAPITypes";

// Extend TeacherDataOut to include our additional fields
export interface Teacher extends TeacherDataOut {
  podcastScript?: string;
  podcastAudioUrl?: string | null;
  isData?: boolean;
}

// Get the Monday of a given week
export const getWeekStart = (date: Date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

// Format date for API calls
export const formatDateForApi = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Get date from n weeks ago
export const getDateWeeksAgo = (weeks: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - weeks * 7);
  return date;
};

// Base teacher data that will always be available
export const baseTeachers: Teacher[] = [
  {
    id: "1",
    first_name: "Buster",
    last_name: "Baxter",
    action_steps: [],
    observation_notes: "",
    scheduled_texts: "none",
    practice_session: "none",
    etdata: null,
    podcast: "none",
    podcastScript: "",
    podcastAudioUrl: null,
    podcast_error: null,
    teacher_id: "1",
    coach_id: "coach1",
    week_start: "",
    addl_context: null,
    last_updated: new Date().toISOString(),
  },
  {
    id: "2",
    first_name: "Jake",
    last_name: "Peralta",
    action_steps: [],
    observation_notes: "",
    scheduled_texts: "none",
    practice_session: "none",
    etdata: null,
    podcast: "none",
    podcastScript: "",
    podcastAudioUrl: null,
    podcast_error: null,
    teacher_id: "2",
    coach_id: "coach1",
    isData: false,
    week_start: "",
    addl_context: null,
    last_updated: new Date().toISOString(),
  },
  {
    id: "3",
    first_name: "Muffy",
    last_name: "Crosswire",
    action_steps: [],
    observation_notes: "",
    scheduled_texts: "none",
    practice_session: "none",
    etdata: null,
    podcast: "none",
    podcastScript: "",
    podcastAudioUrl: null,
    podcast_error: null,
    teacher_id: "3",
    coach_id: "coach1",
    isData: false,
    week_start: "",
    addl_context: null,
    last_updated: new Date().toISOString(),
  },
];

// Current week data
const currentWeekTeachers: Teacher[] = [
  {
    id: "1",
    first_name: "Buster",
    last_name: "Baxter",
    action_steps: ["Improve math language", "Improve precision"],
    observation_notes: "Good progress with classroom management techniques",
    scheduled_texts: "completed",
    practice_session: "in_progress",
    etdata: [75],
    podcast: "completed",
    addl_context: null,
    last_updated: new Date().toISOString(),
    podcastScript: `Hey there, Buster. I heard your coach recently observed your class and had some key points for improvement. Let's dive into this week's lesson briefing to help address those areas while focusing on your main PD goal, increasing rigor in questioning.

Absolutely. This week we're continuing with Topic B, where students solve problems using expressions, equations, and inequalities. The focus question is, how can we use the properties of inequalities to solve problems, which aligns perfectly with our module's essential question about using expressions and equations.

Looking at past exit ticket data, mastery has been hovering around 50-75%. Lessons 10 and 11 showed lower mastery at 55-57%, so we'll definitely want to keep an eye on reinforcing those skills with inequalities this week.

Our aim is to bump that mastery level up.

So what's the game plan this week? We're building on the equation skills from Lessons 7 through 11 and extending that knowledge to inequalities in Lessons 12 and 13. In Lesson 12, students will explore properties of inequalities and translate word problems into inequality statements.

Then Lesson 13 focuses on actually solving those inequalities. This directly builds on the skills of using equality properties to isolate variables and solve equations from prior lessons. We're just adding that extra layer of inequality symbols and solution sets.

And looking ahead, these inequality skills will come in handy for graphing solutions on number lines in future lessons. So this week is a crucial bridge. With potential misconceptions around inequality symbols and multiple solutions, what does mastery look like here?

Great question. Look for students confidently manipulating inequalities, knowing when and why to flip the inequality symbol. They should be able to generate multiple solutions and identify when an inequality has been fully solved.

Definitely. And listen for discussions using precise language like greater than, at least, no more than. Students should be articulating their reasoning for each solving step.

Your coach mentioned improving math language and precision, right? Effectively model this. When a student uses vague language, rephrase their response using the correct terminology.

Absolutely. And for your PD goal of increasing questioning rigor, plan out some targeted questions ahead of time. For example, in Lesson 12's exploratory challenge, you could ask, What happens to the inequality if we use fractions instead of integers?

Love that. And in Lesson 13's discussion, ask students to justify steps, compare inequalities, and interpret solutions in the context of the original problem.

Jot down three or four of these higher order questions before each lesson. That intentional planning will help improve your questioning rigor.

All right, let's recap. This week, we're using the properties of inequalities to solve problems, building on those prior equation skills students have been working on. Prioritize precise math language and incorporate those rigorous questions we talked about to deepen understanding and address potential misconceptions.

You've got this, Buster. Focus on making small improvements in language and questioning each day. Those incremental changes will boost student mastery over time.

Next up, you'll be graphing inequality solutions and integrating algebra with geometry. So lay that strong foundation with inequalities now to set students up for success. Keep up the great work growing your teaching practice. Enjoy exploring inequalities with your students this week.`,
    podcastAudioUrl:
      "https://tlpodcastdemo.s3.us-east-1.amazonaws.com/BusterBaxter_eureka_12-13.mp3",
    podcast_error: null,
    teacher_id: "1",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-16",
  },
  {
    id: "2",
    first_name: "Jake",
    last_name: "Peralta",
    action_steps: [
      "Focus on differentiated instruction",
      "Update lesson plans",
    ],
    addl_context: "",
    last_updated: new Date().toISOString(),
    observation_notes: "Working on student engagement strategies",
    scheduled_texts: "in_progress",
    practice_session: "failed",
    etdata: [62],
    podcast: "none",
    podcastScript: "",
    podcast_error: null,
    teacher_id: "2",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-16",
  },
  {
    id: "3",
    first_name: "Muffy",
    last_name: "Crosswire",
    action_steps: [
      "Focus on differentiated instruction",
      "Update lesson plans",
    ],
    addl_context: "",
    last_updated: new Date().toISOString(),
    observation_notes: "Working on student engagement strategies",
    scheduled_texts: "failed",
    practice_session: "completed",
    etdata: [58],
    podcast: "none",
    podcastScript: "",
    podcast_error: null,
    teacher_id: "3",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-16",
  },
];

// One week ago data
const oneWeekAgoTeachers: Teacher[] = [
  {
    id: "1",
    first_name: "Buster",
    last_name: "Baxter",
    action_steps: ["Implement exit ticket strategies", "Improve questioning"],
    observation_notes: "Needs to work on mathematical language precision",
    scheduled_texts: "completed",
    practice_session: "in_progress",
    etdata: [57],
    addl_context: null,
    last_updated: new Date().toISOString(),
    podcast: "completed",
    podcastScript: `Hey Buster, this is your weekly coaching podcast for last week.

I observed your class working on equations and noticed that while students were engaged, there were opportunities to improve the precision of mathematical language. When discussing equations, try to consistently use terms like "coefficient," "variable," and "constant" rather than more general terms.

Your exit ticket data shows about 57% mastery, which is a good start but we want to push that higher. For this week, I'd like you to focus on implementing more structured exit ticket strategies and improving your questioning techniques during lessons.

Let's connect later this week to discuss specific strategies you can use to increase student mastery. Keep up the good work with classroom management - that's definitely a strength I observed.`,
    podcastAudioUrl:
      "https://tlpodcastdemo.s3.us-east-1.amazonaws.com/BusterBaxter_eureka_12-13.mp3",
    podcast_error: null,
    teacher_id: "1",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-09",
  },
  {
    id: "2",
    first_name: "Jake",
    last_name: "Peralta",
    action_steps: [
      "Implement classroom management strategies",
      "Improve lesson pacing",
    ],
    observation_notes: "Lessons are engaging but timing needs work",
    addl_context: null,
    last_updated: new Date().toISOString(),
    scheduled_texts: "in_progress",
    practice_session: "completed",
    etdata: [60],
    podcast: "completed",
    podcastScript: `Hi Jake, this is your weekly coaching podcast for last week.

I noticed during my observation that your lessons are highly engaging - students are clearly enjoying the activities you've designed. However, I noticed that timing is an issue, with some activities running longer than planned and others being rushed.

For this week, I'd recommend focusing on improving your lesson pacing and implementing more structured classroom management strategies, particularly during transitions between activities.

Your exit ticket data shows 60% mastery, which is solid but we can definitely improve. Let's meet to discuss specific strategies for tightening up transitions while maintaining the high engagement levels you've established.`,
    podcastAudioUrl:
      "https://tlpodcastdemo.s3.us-east-1.amazonaws.com/BusterBaxter_eureka_12-13.mp3",
    podcast_error: null,
    teacher_id: "2",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-09",
  },
  {
    id: "3",
    first_name: "Muffy",
    last_name: "Crosswire",
    action_steps: [
      "Develop differentiation strategies",
      "Improve student feedback",
    ],
    observation_notes:
      "Strong content knowledge but needs to differentiate more",
    addl_context: null,
    last_updated: new Date().toISOString(),
    scheduled_texts: "failed",
    practice_session: "in_progress",
    etdata: [65],
    podcast: "completed",
    podcastScript: `Hello Muffy, this is your weekly coaching podcast for last week.

During my observation, I was impressed by your strong content knowledge and clear explanations. However, I noticed that some students were struggling to keep up while others seemed ready for more challenging work.

For this week, I'd like you to focus on developing differentiation strategies to meet the needs of all learners in your classroom. Additionally, work on providing more specific feedback to students during independent work time.

Your exit ticket data shows 65% mastery, which is good, but with better differentiation, we can likely push that higher. Let's schedule a time to discuss specific strategies you can implement right away.`,
    podcastAudioUrl:
      "https://tlpodcastdemo.s3.us-east-1.amazonaws.com/BusterBaxter_eureka_12-13.mp3",
    podcast_error: null,
    teacher_id: "3",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-09",
  },
];

// Two weeks ago data
const twoWeeksAgoTeachers: Teacher[] = [
  {
    id: "1",
    first_name: "Buster",
    last_name: "Baxter",
    action_steps: [
      "Improve classroom management",
      "Enhance student engagement",
    ],
    observation_notes:
      "Students are engaged but classroom management needs work",
    scheduled_texts: "completed",
    practice_session: "completed",
    addl_context: null,
    last_updated: new Date().toISOString(),
    etdata: [50],
    podcast: "completed",
    podcastScript: `Hi Buster, this is your weekly coaching podcast from two weeks ago.

During my observation, I noticed that while many students were engaged in the lesson, there were some classroom management issues that disrupted the flow of learning. Specifically, transitions between activities took longer than necessary and a few students were off-task during independent work time.

Your exit ticket data shows about 50% mastery, which gives us room for improvement. For next week, I'd like you to focus on implementing clearer classroom management routines and enhancing student engagement through more structured participation strategies.

Let's meet to discuss specific techniques you can use to address these areas. I'm confident that with some targeted adjustments, we'll see improvements in both classroom management and student mastery.`,
    podcastAudioUrl:
      "https://tlpodcastdemo.s3.us-east-1.amazonaws.com/BusterBaxter_eureka_12-13.mp3",
    podcast_error: null,
    teacher_id: "1",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-02",
  },
  {
    id: "2",
    first_name: "Jake",
    last_name: "Peralta",
    action_steps: [
      "Develop lesson planning skills",
      "Improve assessment strategies",
    ],
    observation_notes: "Engaging personality but lessons lack structure",
    scheduled_texts: "in_progress",
    practice_session: "in_progress",
    addl_context: null,
    last_updated: new Date().toISOString(),
    etdata: [52],
    podcast: "completed",
    podcastScript: `Hey Jake, this is your weekly coaching podcast from two weeks ago.

I really enjoyed observing your class - your enthusiasm and rapport with students is excellent. However, I noticed that your lessons could benefit from more structure and clear learning objectives.

Your exit ticket data shows 52% mastery, which suggests we have opportunities to improve. For next week, I'd recommend focusing on developing more structured lesson plans with clear objectives and improving your formative assessment strategies throughout the lesson.

Let's schedule a time to work together on lesson planning. With your natural ability to engage students combined with more structured planning, I believe we'll see significant improvements in student outcomes.`,
    podcastAudioUrl:
      "https://tlpodcastdemo.s3.us-east-1.amazonaws.com/JakePeralta_eureka_12-13.mp3",
    podcast_error: null,
    teacher_id: "2",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-02",
  },
  {
    id: "3",
    first_name: "Muffy",
    last_name: "Crosswire",
    action_steps: [
      "Improve student-centered learning",
      "Enhance questioning techniques",
    ],
    observation_notes:
      "Teacher-centered instruction with limited student voice",
    scheduled_texts: "failed",
    practice_session: "failed",
    addl_context: null,
    last_updated: new Date().toISOString(),
    etdata: [55],
    podcast: "completed",
    podcastScript: `Hello Muffy, this is your weekly coaching podcast from two weeks ago.

During my observation, I noticed that your instruction tends to be very teacher-centered, with limited opportunities for student voice and collaboration. While your explanations are clear, students would benefit from more opportunities to engage with the content and with each other.

Your exit ticket data shows 55% mastery, which is a solid foundation to build upon. For next week, I'd like you to focus on incorporating more student-centered learning activities and enhancing your questioning techniques to promote deeper thinking.

Let's meet to discuss specific strategies you can implement to shift toward more student-centered instruction while maintaining the clarity that is currently a strength in your teaching.`,
    podcastAudioUrl:
      "https://tlpodcastdemo.s3.us-east-1.amazonaws.com/MuffyCrosswire_eureka_12-13.mp3",
    podcast_error: null,
    teacher_id: "3",
    coach_id: "coach1",
    isData: true,
    week_start: "2024-09-02",
  },
];

// Map of week start dates to teacher data
export const weeklyTeacherData = new Map<string, Teacher[]>();

// Current week
const currentWeekStart = getWeekStart(new Date());
weeklyTeacherData.set(formatDateForApi(currentWeekStart), currentWeekTeachers);

// One week ago
const oneWeekAgoStart = getWeekStart(getDateWeeksAgo(1));
weeklyTeacherData.set(formatDateForApi(oneWeekAgoStart), oneWeekAgoTeachers);

// Two weeks ago
const twoWeeksAgoStart = getWeekStart(getDateWeeksAgo(2));
weeklyTeacherData.set(formatDateForApi(twoWeeksAgoStart), twoWeeksAgoTeachers);

// Mock API functions
export const fetchTeacherData = async (weekStart: Date): Promise<Teacher[]> => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 500));

  const formattedDate = formatDateForApi(weekStart);
  const data = weeklyTeacherData.get(formattedDate);

  if (!data) {
    // If no data for this week, return base teachers with empty values
    const baseTeachersWithDate = baseTeachers.map((teacher) => ({
      ...teacher,
      week_start: formattedDate,
    }));
    return baseTeachersWithDate;
  }

  // Add week_start and isData to the data
  const dataWithMetadata = data.map((teacher) => {
    // Ensure all required fields from TeacherDataOut are present
    return {
      ...teacher,
      week_start: formattedDate,
      isData: true,
      // Ensure the API-compatible fields are set
      podcast: teacher.podcast || "none",
      practice_session: teacher.practice_session || "none",
      scheduled_texts: teacher.scheduled_texts || "none",
      observation_notes: teacher.observation_notes || "",
      action_steps: teacher.action_steps || [],
      teacher_id: teacher.teacher_id || teacher.id,
      coach_id: teacher.coach_id || "coach1",
      first_name: teacher.first_name,
      last_name: teacher.last_name,
      etdata: teacher.etdata,
      podcast_error: teacher.podcast_error || null,
      addl_context: teacher.addl_context || null,
      last_updated: teacher.last_updated || new Date().toISOString(),
    };
  });

  return [...dataWithMetadata]; // Return a copy to avoid mutations
};

export const generatePodcast = async (
  teacherId: string,
  weekStart: Date,
): Promise<Teacher> => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const formattedDate = formatDateForApi(weekStart);
  const weekData = weeklyTeacherData.get(formattedDate);

  if (!weekData) {
    throw new Error("No data found for the specified week");
  }

  const teacher = weekData.find((t) => t.id === teacherId);

  if (!teacher) {
    throw new Error("Teacher not found");
  }

  // Only allow generation for current week
  const currentWeekStart = formatDateForApi(getWeekStart(new Date()));
  if (formattedDate !== currentWeekStart) {
    throw new Error(
      "Podcast generation is only available for the current week",
    );
  }

  // Create updated teacher with podcast
  const updatedTeacher: Teacher = {
    ...teacher,
    podcast: "completed",
    podcastScript: `Hi ${teacher.first_name}, this is your weekly coaching podcast. 

Based on our recent observations, I've noticed you've been ${teacher.observation_notes?.toLowerCase()}

For this week, I'd like you to focus on ${Array.isArray(teacher.action_steps) ? teacher.action_steps.join(", ") : ""}. 

Let me know if you need any support with these items. Looking forward to our next session!`,
    podcastAudioUrl: "https://example.com/podcast.mp3",
    podcast_error: null,
    last_updated: new Date().toISOString(),
  };

  // Update the data in our mock database
  const updatedWeekData = weekData.map((t) =>
    t.id === teacherId ? updatedTeacher : t,
  );

  weeklyTeacherData.set(formattedDate, updatedWeekData);

  return updatedTeacher;
};

export const regeneratePodcast = async (
  teacherId: string,
  weekStart: Date,
): Promise<Teacher> => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 1500));

  const formattedDate = formatDateForApi(weekStart);
  const weekData = weeklyTeacherData.get(formattedDate);

  if (!weekData) {
    throw new Error("No data found for the specified week");
  }

  const teacher = weekData.find((t) => t.id === teacherId);

  if (!teacher) {
    throw new Error("Teacher not found");
  }

  // Only allow regeneration for current week
  const currentWeekStart = formatDateForApi(getWeekStart(new Date()));
  if (formattedDate !== currentWeekStart) {
    throw new Error(
      "Podcast regeneration is only available for the current week",
    );
  }

  // Create updated teacher with regenerated podcast
  const updatedTeacher: Teacher = {
    ...teacher,
    podcast: "completed",
    podcastScript: `[REGENERATED] Hi ${teacher.first_name}, this is your updated weekly coaching podcast. 

Based on our recent observations, I've noticed you've been ${teacher.observation_notes?.toLowerCase()}

For this week, I'd like you to focus on ${Array.isArray(teacher.action_steps) ? teacher.action_steps.join(", ") : ""}. 

Let me know if you need any support with these items. Looking forward to our next session!`,
    podcast_error: null,
    last_updated: new Date().toISOString(),
  };

  // Update the data in our mock database
  const updatedWeekData = weekData.map((t) =>
    t.id === teacherId ? updatedTeacher : t,
  );

  weeklyTeacherData.set(formattedDate, updatedWeekData);

  return updatedTeacher;
};

export const savePodcastScript = async (
  teacherId: string,
  weekStart: Date,
  script: string,
): Promise<Teacher> => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 1000));

  const formattedDate = formatDateForApi(weekStart);
  const weekData = weeklyTeacherData.get(formattedDate);

  if (!weekData) {
    throw new Error("No data found for the specified week");
  }

  const teacher = weekData.find((t) => t.id === teacherId);

  if (!teacher) {
    throw new Error("Teacher not found");
  }

  // Only allow saving for current week
  const currentWeekStart = formatDateForApi(getWeekStart(new Date()));
  if (formattedDate !== currentWeekStart) {
    throw new Error(
      "Podcast script editing is only available for the current week",
    );
  }

  // Create updated teacher with edited script
  const updatedTeacher: Teacher = {
    ...teacher,
    podcastScript: script,
    podcast_error: null,
    last_updated: new Date().toISOString(),
  };

  // Update the data in our mock database
  const updatedWeekData = weekData.map((t) =>
    t.id === teacherId ? updatedTeacher : t,
  );

  weeklyTeacherData.set(formattedDate, updatedWeekData);

  return updatedTeacher;
};
