import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useMutation } from "@tanstack/react-query";
import { Lesson, LessonSegment } from "./types";
import { useAuth } from "../../../../contexts/AuthContext";
import { generateLessonBuilderLessonSegments } from "../../../../api/nisaAPI";

interface BrainstormSpaceProps {
  lesson: Lesson;
  onDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    segment: LessonSegment,
    fromBuilder: boolean,
    rowIndex: number,
    segmentIndex: number,
  ) => void;
}

const BrainstormSpace: React.FC<BrainstormSpaceProps> = ({
  lesson,
  onDragStart,
}) => {
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [segmentPages, setSegmentPages] = useState<LessonSegment[][]>([]);
  const [expandedSegment, setExpandedSegment] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [newContentPage, setNewContentPage] = useState<number | null>(null);
  const [recentlyGenerated, setRecentlyGenerated] = useState<boolean>(false);
  const lastContentRef = useRef<string>(lesson.content);
  const isInitialized = useRef(false);
  const { currentUser } = useAuth();

  const maxPages = 3;

  const { mutateAsync: generateSegments, isPending } = useMutation({
    mutationFn: async () => {
      const token = currentUser?.accessToken;
      return await generateLessonBuilderLessonSegments(lesson.id, token);
    },
  });

  useEffect(() => {
    const initializeSegments = async () => {
      if (!isInitialized.current) {
        await generateMoreSegments();
        setInitialLoading(false);
        isInitialized.current = true;
      }
    };

    initializeSegments();
  }, []);

  const generateMoreSegments = async () => {
    const segmentsResponse = await generateSegments();
    const newSegments = segmentsResponse.segments.map(
      (segment: LessonSegment, index: number) => ({
        ...segment,
        id: segment.id || `${Date.now()}-${index}`,
      }),
    );
    setSegmentPages((prevSegmentPages) => {
      let updatedSegmentPages = [...prevSegmentPages, newSegments];
      if (updatedSegmentPages.length > maxPages) {
        updatedSegmentPages = updatedSegmentPages.slice(-maxPages);
      }
      setNewContentPage(updatedSegmentPages.length - 1);
      return updatedSegmentPages;
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (lesson.content !== lastContentRef.current) {
      lastContentRef.current = lesson.content;
      setRecentlyGenerated(false);

      if (!recentlyGenerated) {
        timer = setTimeout(() => {
          if (lastContentRef.current === lesson.content) {
            generateMoreSegments();
          }
        }, 3000);
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [lesson.content, recentlyGenerated]);

  const toggleExpand = (id: string) => {
    setExpandedSegment(expandedSegment === id ? null : id);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    segment: LessonSegment,
  ) => {
    onDragStart(e, segment, false, -1, -1);
  };

  const pageVariants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5,
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  if (initialLoading) {
    return (
      <div className="brainstorm-space">
        <h2>Brainstorm Space</h2>
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Initializing brainstorm space...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="brainstorm-space">
      <h2>Brainstorm Space</h2>
      <AnimatePresence initial={false} custom={currentPage}>
        <motion.div
          key={currentPage}
          custom={currentPage}
          variants={pageVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={pageTransition}
          className="brainstorm-segments-grid"
        >
          {segmentPages[currentPage].map((segment, index) => (
            <div
              key={segment.id}
              className="segment-card-wrapper"
              draggable
              onDragStart={(e) => handleDragStart(e, segment)}
            >
              <motion.div
                className={`segment-card ${expandedSegment === segment.id ? "expanded" : ""}`}
                onClick={() => segment.id && toggleExpand(segment.id)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 3, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <h3>{segment.segment_title}</h3>
                {expandedSegment === segment.id && <p>{segment.short_text}</p>}
                {segment.coach_related && (
                  <span className="coach-related-badge">
                    action step practice
                  </span>
                )}
              </motion.div>
            </div>
          ))}
          {isPending && currentPage === segmentPages.length - 1 && (
            <div className="loading-overlay">
              <div className="spinner"></div>
              <p>Generating new segments...</p>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
      <div className="pagination">
        {segmentPages.map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index)}
            className={`page-indicator ${currentPage === index ? "active" : ""} ${index === newContentPage ? "new-content" : ""}`}
          >
            {index === newContentPage && index !== currentPage
              ? "🆕"
              : index + 1}
          </button>
        ))}
      </div>
      <div className="generate-more-container">
        <button
          className="generate-more-btn"
          onClick={generateMoreSegments}
          disabled={isPending}
        >
          {isPending ? "Generating..." : "Generate More Activities"}
        </button>
      </div>
    </div>
  );
};

export default BrainstormSpace;
