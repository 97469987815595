import React from "react";
import { Box, Flex } from "@mantine/core";
import {
  SimpleLayout,
  SimpleLayoutFooter,
  SimpleLayoutMain,
} from "../../layouts/SimpleLayout";
import NisaFooter, { NisaFooterRef } from "../../ui/Footer";
import { useElementSize } from "@mantine/hooks";

export const UnpackerLayout: React.FC<
  React.PropsWithChildren<{
    footerRef?: React.RefObject<NisaFooterRef>;
  }>
> = ({ children, footerRef }) => {
  const { ref, height } = useElementSize();

  return (
    <SimpleLayout>
      <SimpleLayoutMain>
        <Box
          mih={`calc(100vh - ${height !== undefined ? height + 1 : 0}px)`}
          miw="100%"
          style={{
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 10L20 10L20 20L10 20Z' fill='%23e7f20f' stroke='%23000' stroke-width='1'/%3E%3Cpath d='M40 15C45 15 45 25 40 25C35 25 35 15 40 15Z' fill='%23e7f20f' stroke='%23000' stroke-width='1'/%3E%3Cpath d='M15 35L25 45L35 35' fill='%23e7f20f' stroke='%23000' stroke-width='1'/%3E%3Cpath d='M45 35C45 40 40 45 35 45' fill='%23e7f20f' stroke='%23000' stroke-width='1'/%3E%3Cpath d='M10 50L20 50L20 55L15 58L10 55Z' fill='%23e7f20f' stroke='%23000' stroke-width='1'/%3E%3Cpath d='M50 5L50 15L45 10L50 5' fill='%23e7f20f' stroke='%23000' stroke-width='1'/%3E%3Cpath d='M30 25C30 28 28 30 25 30' fill='%23e7f20f' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E\")",
            backgroundRepeat: "repeat",
            backgroundSize: "60px",
          }}
        >
          <Flex
            direction="column"
            justify="center"
            align="center"
            mih={`calc(100vh - ${height !== undefined ? height + 1 : 0}px)`}
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              backdropFilter: "blur(1px)",
            }}
          >
            {children}
          </Flex>
        </Box>
      </SimpleLayoutMain>
      <SimpleLayoutFooter ref={ref}>
        <NisaFooter ref={footerRef} />
      </SimpleLayoutFooter>
    </SimpleLayout>
  );
};
