import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { FormLayout } from "./ui/formLayout";
import { useAuth } from "../contexts/AuthContext";
import {
  ClientID,
  fetchAndCacheAccountInfo,
  getCachedClientID,
} from "../utils/sessionUtils";
import {
  SplitLayout,
  SplitLayoutMain,
  SplitLayoutSection,
} from "./layouts/SplitLayout";
import { Anchor, Flex, Stack, Text } from "@mantine/core";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, loginWithGoogle } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    await loginUser(() => login(email, password));
  };

  const handleGoogleLogin = async () => {
    await loginUser(loginWithGoogle);
  };

  const loginUser = async (
    loginMethod: typeof loginWithGoogle | typeof login,
  ) => {
    try {
      setError("");
      setLoading(true);

      // Call the login method
      const userCredential = await loginMethod();
      const token = await userCredential.user.getIdToken();

      await fetchAndCacheAccountInfo(token);

      const clientID = getCachedClientID();

      // Navigate based on the client_id
      if (clientID === ClientID.TEACHER) {
        navigate("/teacher");
      } else if (clientID === ClientID.COACH) {
        navigate("/");
      } else {
        console.error("Unknown client_id received:", clientID);
        navigate("/login");
      }
    } catch (error) {
      if (
        error instanceof Error &&
        "response" in error &&
        error.response instanceof Response &&
        error.response.status === 404
      ) {
        setError("No user found with this login");
      } else {
        setError("Failed to sign in");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <SplitLayout>
      <SplitLayoutMain>
        <SplitLayoutSection bg="white" mih="100vh">
          <Stack p={{ base: "lg", md: "xl" }}>
            <Text ta="center">
              NISA is an AI-powered educational platform providing teachers and
              instructional coaches the support they need to become{" "}
              <strong>experts at their craft</strong>
            </Text>
            <Text ta="center">
              It&apos;s an <strong>ecosystem</strong> that runs on the real
              insights generated by <strong>human coaches</strong> during
              classroom observations and coaching conversations.
            </Text>
            <Text ta="center">
              NISA <i>collaborates</i> with coaches to turn those insights into
              a full suite of supports for teachers - from experiences like
              unpacker to interactive lesson rehearsals, right-time text message
              support, custom podcasts, novel lesson planning tools and more.
            </Text>
            <Text ta="center">
              The result is a set of <strong>wrap-around supports</strong> that
              are <i>aware of and responsive to the unique, dynamic context</i>{" "}
              of each teacher&apos;s classroom.
            </Text>
            <Text mt="md" c="dimmed" ta="center">
              NISA is being developed by{" "}
              <Anchor
                href="https://linkedin.com/in/agasthyaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                Agasthya Shenoy
              </Anchor>
              , an AI Fellow at{" "}
              <Anchor
                href="https://teachinglabstudio.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Teaching Lab Studio
              </Anchor>
              .
            </Text>
            <Text c="dimmed" ta="center">
              Want to stay in the loop?{" "}
              <Anchor
                href="https://forms.gle/wpTedLWeqrVZgupT6"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </Anchor>
              !
            </Text>
          </Stack>
        </SplitLayoutSection>
        <SplitLayoutSection>
          <FormLayout title="nisa" subtitle="_controlcenter" className="w-full">
            <form
              onSubmit={handleLogin}
              className="bg-white bg-opacity-0 rounded-lg p-8 backdrop-filter backdrop-blur-md"
            >
              <div className="space-y-4">
                <Input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
                />
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
                />
              </div>
              {error && <p className="text-red-500 mt-2">{error}</p>}
              <div className="flex justify-center w-full mt-6">
                <Button
                  disabled={loading}
                  type="submit"
                  variant="ghost"
                  className="w-1/2 text-white font-light rounded-full border border-transparent hover:border-white hover:bg-transparent hover:text-white transition-all duration-200 shadow-submit"
                >
                  Log In
                </Button>
              </div>
              <div className="flex justify-center w-full mt-4">
                <Button
                  disabled={loading}
                  onClick={handleGoogleLogin}
                  variant="ghost"
                  className="w-1/2 text-white font-light rounded-full border border-transparent hover:border-white hover:bg-transparent hover:text-white transition-all duration-200 shadow-submit"
                >
                  Sign in with Google
                </Button>
              </div>
            </form>
            <div className="w-100 text-center mt-2">
              Need an account? <Link to="/signup">Sign Up</Link>
            </div>
            <div className="w-100 text-center mt-2 text-yellow-400">
              <Link
                to="/toolbox"
                className="flex items-center align-middle justify-center"
              >
                <div className="inline-block overflow-hidden">
                  <img
                    src="/toolbox.png"
                    alt="Toolbox"
                    width="64"
                    height="64"
                    className="translate-y-[-100px] drop-shadow-[0_100px_0_#facc15]"
                  />
                </div>
                <p className="inline">or test tools with quickstart</p>
              </Link>
            </div>
          </FormLayout>
        </SplitLayoutSection>
      </SplitLayoutMain>
    </SplitLayout>
  );
};

export default Login;
