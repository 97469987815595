import React, { useEffect, useState, useRef } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Card,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  Stack,
  Table,
  Text,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import { CalendarDays, ChevronLeft, ChevronRight } from "lucide-react";
import {
  SingleColumnLayout,
  SingleColumnLayoutFooter,
  SingleColumnLayoutMain,
} from "../../layouts/SingleColumnLayout";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import NisaFooter from "../../ui/Footer";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Teacher,
  fetchTeacherData,
  formatDateForApi,
  generatePodcast,
  getWeekStart,
  savePodcastScript,
} from "../data/mockTeacherData";
import { SupportState } from "../../../api/nisaAPITypes";

const PodcastsPage: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<Teacher | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedScript, setEditedScript] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // Get the Monday of the current week as the initial state
  const [currentWeek, setCurrentWeek] = useState<Date>(
    getWeekStart(new Date()),
  );

  // Fetch teacher data for the selected week
  const {
    data: teachers = [],
    isPending,
    isPlaceholderData,
  } = useQuery({
    queryKey: ["teachers", formatDateForApi(currentWeek)],
    queryFn: () => fetchTeacherData(currentWeek),
    placeholderData: keepPreviousData,
  });

  // Generate podcast mutation
  const generatePodcastMutation = useMutation({
    mutationFn: ({
      teacherId,
      weekStart,
    }: {
      teacherId: string;
      weekStart: Date;
    }) => generatePodcast(teacherId, weekStart),
    onSuccess: (updatedTeacher) => {
      queryClient.invalidateQueries({
        queryKey: ["teachers", formatDateForApi(currentWeek)],
      });
    },
  });

  // Save podcast script mutation
  const savePodcastScriptMutation = useMutation({
    mutationFn: ({
      teacherId,
      weekStart,
      script,
    }: {
      teacherId: string;
      weekStart: Date;
      script: string;
    }) => savePodcastScript(teacherId, weekStart, script),
    onSuccess: (updatedTeacher) => {
      queryClient.invalidateQueries({
        queryKey: ["teachers", formatDateForApi(currentWeek)],
      });
      setSelectedTeacher(updatedTeacher);
      setIsEditing(false);
    },
  });

  // Check if user is logged in and show modal if not
  useEffect(() => {
    if (!currentUser) {
      setShowLoginModal(true);
    }
  }, [currentUser]);

  const handleContinueWithDemo = () => {
    setShowLoginModal(false);
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  const navigateWeek = (direction: number) => {
    setCurrentWeek((prevWeek) => {
      const newWeek = new Date(prevWeek);
      newWeek.setDate(newWeek.getDate() + direction * 7);
      return getWeekStart(newWeek);
    });
  };

  const resetToCurrentWeek = () => {
    setCurrentWeek(getWeekStart(new Date()));
  };

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const isCurrentWeek = (date: Date) => {
    const today = new Date();
    const currentWeekStart = getWeekStart(today);
    const dateWeekStart = getWeekStart(date);
    return (
      formatDateForApi(currentWeekStart) === formatDateForApi(dateWeekStart)
    );
  };

  // Helper function to convert string to title case
  const toTitleCase = (str: string): string => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Get button text based on status
  const getButtonText = (
    status: SupportState | string | undefined,
    isCurrentWeek = true,
  ): string => {
    if (!isCurrentWeek) {
      return "View";
    }

    switch (status) {
      case "none":
        return "Generate";
      case "in_progress":
        return "Generating...";
      case "completed":
        return "View";
      case "failed":
        return "Retry";
      case "update_flag":
        return "Update";
      default:
        return "Generate";
    }
  };

  // Check if button should be disabled
  const isButtonDisabled = (
    status: SupportState | string | undefined,
    isCurrentWeek = true,
  ): boolean => {
    if (!isCurrentWeek) {
      return status !== "completed";
    }

    return status === "in_progress";
  };

  // Handle podcast button click
  const handlePodcastButtonClick = (teacher: Teacher) => {
    if (teacher.podcast === "none" || !teacher.podcast) {
      // Check if this is the current week
      if (!isCurrentWeek(currentWeek)) {
        alert("Podcast generation is only available for the current week");
        return;
      }

      // Generate podcast
      generatePodcastMutation.mutate({
        teacherId: teacher.id,
        weekStart: currentWeek,
      });
    } else if (teacher.podcast === "completed") {
      // Open modal to view podcast
      setSelectedTeacher(teacher);
      setIsEditing(false);
      setIsModalOpen(true);
    }
  };

  // Handle edit button click
  const handleEdit = () => {
    if (!isCurrentWeek(currentWeek)) {
      alert("Podcast script editing is only available for the current week");
      return;
    }
    setIsEditing(true);
    setEditedScript(selectedTeacher?.podcastScript || "");

    // Focus on textarea after a short delay to ensure it's rendered
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 100);
  };

  // Handle cancel button click
  const handleCancel = () => {
    setIsEditing(false);
    setEditedScript(selectedTeacher?.podcastScript || "");
  };

  // Handle save button click
  const handleSave = () => {
    if (selectedTeacher) {
      if (!isCurrentWeek(currentWeek)) {
        alert("Podcast script editing is only available for the current week");
        return;
      }

      savePodcastScriptMutation.mutate({
        teacherId: selectedTeacher.id,
        weekStart: currentWeek,
        script: editedScript,
      });
    }
  };

  return (
    <SingleColumnLayout>
      <SingleColumnLayoutMain>
        <Modal
          opened={showLoginModal}
          onClose={() => setShowLoginModal(false)}
          title="Login Required"
          centered
          closeOnClickOutside={false}
          closeOnEscape={false}
          withCloseButton={false}
          zIndex={1100}
        >
          <Stack gap="md">
            <Text size="sm">
              Welcome to the NISA podcasts demo. You are{" "}
              {currentUser ? "currently logged in" : "not logged in"} to the
              NISA platform.
            </Text>
            <Text size="sm">
              By logging in or creating an account, you can access your actual
              data and track teacher progress over time.
            </Text>
            <Text size="sm">
              Would you like to try out the podcast functionality with demo data
              or log in to see your actual data?
            </Text>

            <Text size="xs" c="red">
              Note: In demo mode audio may be unavailable after editing or
              regenerating podcasts.
            </Text>
            <Group justify="center" gap="md" mt="md">
              <Button variant="outline" onClick={handleNavigateToLogin}>
                Log In
              </Button>
              <Button onClick={handleContinueWithDemo}>
                Continue with Demo Data
              </Button>
            </Group>
          </Stack>
        </Modal>

        {/* Podcast Script Modal */}
        <Modal
          opened={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setIsEditing(false);
            setSelectedTeacher(null);
          }}
          title={
            selectedTeacher
              ? `Podcast for ${selectedTeacher.first_name} ${selectedTeacher.last_name}`
              : "Podcast"
          }
          size="xl"
          centered
        >
          <Card withBorder>
            <Card.Section p="md" withBorder>
              <Title order={5}>Details</Title>
              {selectedTeacher?.podcastAudioUrl && (
                <Box mt="md">
                  <audio controls style={{ width: "100%" }}>
                    <source
                      src={selectedTeacher?.podcastAudioUrl}
                      type="audio/mpeg"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              )}
            </Card.Section>

            <Card.Section p="md">
              <ScrollArea h={300} type="auto">
                {generatePodcastMutation.isPending ||
                savePodcastScriptMutation.isPending ? (
                  <Group>
                    <Loader size="sm" />
                    <Text>Loading podcast content...</Text>
                  </Group>
                ) : isEditing ? (
                  <Textarea
                    ref={textareaRef}
                    value={editedScript}
                    onChange={(e) => setEditedScript(e.target.value)}
                    minRows={10}
                    autosize
                    className="podcast-script-textarea"
                    style={{
                      width: "100%",
                      height: "100%",
                      fontFamily: "inherit",
                    }}
                    styles={(theme) => ({
                      input: {
                        fontFamily: theme.fontFamily,
                        fontSize: theme.fontSizes.sm,
                        lineHeight: 1.55,
                        border: "none",
                        padding: 0,
                        backgroundColor: "transparent",
                      },
                      wrapper: {
                        height: "100%",
                      },
                    })}
                    variant="unstyled"
                  />
                ) : (
                  <Text
                    component="pre"
                    style={{
                      whiteSpace: "pre-wrap",
                      fontFamily: "inherit",
                      fontSize: "var(--mantine-font-size-sm)",
                      lineHeight: 1.55,
                      margin: 0,
                    }}
                  >
                    {selectedTeacher?.podcastScript}
                  </Text>
                )}
              </ScrollArea>
            </Card.Section>
          </Card>

          <Group justify="flex-end" mt="md">
            {!isEditing ? (
              <>
                <Tooltip
                  label="Note: In demo mode, audio will not be regenerated after editing the script"
                  position="top"
                  withArrow
                >
                  <Button
                    onClick={handleEdit}
                    disabled={
                      generatePodcastMutation.isPending ||
                      !isCurrentWeek(currentWeek)
                    }
                  >
                    Edit Script
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <Button variant="outline" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  disabled={savePodcastScriptMutation.isPending}
                >
                  Save
                </Button>
              </>
            )}
          </Group>
        </Modal>

        <Paper p={0} shadow="xs" radius="md" withBorder>
          <Stack gap="xs">
            <Box
              p="md"
              style={(theme) => ({
                borderBottom: `1px solid ${theme.colors.gray[3]}`,
              })}
            >
              <Title order={3} mb="xs">
                Teacher Support Tracker
              </Title>
              <Text c="dimmed" size="sm">
                view and generate teacher supports
              </Text>
            </Box>

            <Box p="md">
              <Stack gap="md">
                <Group justify="center" gap="xs">
                  <ActionIcon onClick={() => navigateWeek(-1)} variant="subtle">
                    <ChevronLeft size={24} />
                  </ActionIcon>

                  <Stack
                    gap=""
                    style={(theme) => ({
                      position: "relative",
                      padding: "4px 8px",
                      borderRadius: theme.radius.sm,
                      fontWeight: isCurrentWeek(currentWeek) ? 700 : 400,
                    })}
                  >
                    <Text size="lg">Week of {formatDate(currentWeek)}</Text>
                    {isCurrentWeek(currentWeek) && (
                      <Box
                        style={(theme) => ({
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: "2px",
                          backgroundColor: theme.colors.green[5],
                          borderRadius: "1px",
                        })}
                      />
                    )}
                  </Stack>

                  <ActionIcon onClick={() => navigateWeek(1)} variant="subtle">
                    <ChevronRight size={24} />
                  </ActionIcon>
                </Group>

                <Group justify="center">
                  <Button
                    variant="transparent"
                    size="xs"
                    leftSection={<CalendarDays size={16} />}
                    onClick={resetToCurrentWeek}
                    disabled={isCurrentWeek(currentWeek)}
                  >
                    Return to Current Week
                  </Button>
                </Group>
              </Stack>
            </Box>

            <Box p="md">
              <Box
                bd="1px solid gray.3"
                style={(theme) => ({
                  borderRadius: theme.radius.md,
                  position: "relative",
                })}
              >
                <LoadingOverlay
                  visible={isPending || isPlaceholderData}
                  overlayProps={{ radius: "sm", blur: 2 }}
                  loaderProps={{ color: "blue", type: "bars" }}
                />

                {teachers.length === 0 ? (
                  <Text ta="center" p="xl">
                    No teacher data available for this week.
                  </Text>
                ) : (
                  <Table striped highlightOnHover>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Teacher Name</Table.Th>
                        <Table.Th>Current Action Steps</Table.Th>
                        <Table.Th>Most Recent Observation Notes</Table.Th>
                        <Table.Th>Weekly Podcast</Table.Th>
                        <Table.Th>Text Check-ins</Table.Th>
                        <Table.Th>Practice Session</Table.Th>
                        <Table.Th>Exit Ticket Data</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {teachers.map((teacher) => (
                        <Table.Tr key={teacher.id}>
                          <Table.Td>
                            {teacher.first_name} {teacher.last_name}
                          </Table.Td>
                          <Table.Td>
                            {Array.isArray(teacher.action_steps)
                              ? teacher.action_steps.join(", ")
                              : ""}
                          </Table.Td>
                          <Table.Td>{teacher.observation_notes}</Table.Td>
                          <Table.Td>
                            <Button
                              variant="light"
                              color="blue"
                              loading={
                                (generatePodcastMutation.isPending &&
                                  generatePodcastMutation.variables
                                    ?.teacherId === teacher.id) ||
                                teacher.podcast === "in_progress"
                              }
                              onClick={() => handlePodcastButtonClick(teacher)}
                              disabled={isButtonDisabled(
                                teacher.podcast,
                                isCurrentWeek(currentWeek),
                              )}
                            >
                              {getButtonText(
                                teacher.podcast,
                                isCurrentWeek(currentWeek),
                              )}
                            </Button>
                          </Table.Td>
                          <Table.Td>
                            {teacher.scheduled_texts &&
                            teacher.scheduled_texts !== "none" ? (
                              <Text>
                                {toTitleCase(teacher.scheduled_texts)}
                              </Text>
                            ) : (
                              <Text color="dimmed">-</Text>
                            )}
                          </Table.Td>
                          <Table.Td>
                            {teacher.practice_session &&
                            teacher.practice_session !== "none" ? (
                              <Text>
                                {toTitleCase(teacher.practice_session)}
                              </Text>
                            ) : (
                              <Text color="dimmed">-</Text>
                            )}
                          </Table.Td>
                          <Table.Td>
                            {teacher.etdata &&
                            Array.isArray(teacher.etdata) &&
                            teacher.etdata.length > 0
                              ? `${teacher.etdata[0]}%`
                              : "N/A"}
                          </Table.Td>
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>
                )}
              </Box>
            </Box>
          </Stack>
        </Paper>
      </SingleColumnLayoutMain>
      <SingleColumnLayoutFooter>
        <NisaFooter />
      </SingleColumnLayoutFooter>
    </SingleColumnLayout>
  );
};

export default PodcastsPage;
