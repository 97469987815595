import { Box, Card, Grid, Image, Text, Title } from "@mantine/core";
import {
  SingleColumnLayout,
  SingleColumnLayoutFooter,
  SingleColumnLayoutMain,
} from "../layouts/SingleColumnLayout";
import NisaFooter from "../ui/Footer";

interface ToolboxApp {
  name: string;
  description: string;
  image: string;
  link: string;
}

// Placeholder data - this could be moved to a separate data file or API call in the future
const dummyApps: ToolboxApp[] = [
  {
    name: "Unpacker",
    description:
      "AI coaches guide you through your upcoming lesson to make sure you're prepared on content and ready to incorporate teaching moves.",
    image: "/unpacker.webp",
    link: "/unpacker",
  },
  {
    name: "Lesson Builder",
    description:
      "Create a custom lesson plan based on the teaching goals of an existing lesson.",
    image: "/lesson-builder.webp",
    link: "/lesson-builder",
  },
  {
    name: "Podcasts",
    description:
      "Prepare a 5-minute podcast for your teacher that takes their upcoming lesson and makes suggestions for best practices.",
    image: "/podcasts.webp",
    link: "/podcasts",
  },
  // Add more dummy apps as needed
];

export function ToolboxPage() {
  return (
    <SingleColumnLayout>
      <SingleColumnLayoutMain>
        <Title order={1} ta="center" mb="sm">
          NISA Toolbox
        </Title>
        <Text ta="center" c="dimmed" mb="xl">
          Explore our suite of NISA applications. Some are experimental and may
          not be fully integrated into the NISA ecosystem yet. These beta apps
          and early releases will be marked with a badge.
        </Text>

        <Grid>
          {dummyApps.map((app, index) => (
            <Grid.Col key={index} span={{ base: 12, sm: 6, md: 4 }}>
              <Card
                component="a"
                href={app.link}
                target="_blank"
                shadow="sm"
                padding="lg"
                radius="md"
                withBorder
                style={{ height: "100%", textDecoration: "none" }}
              >
                <Card.Section>
                  <Image src={app.image} h={250} alt={app.name} />
                </Card.Section>

                <Box mt="md">
                  <Text fw={500} size="lg" mb="xs">
                    {app.name}
                  </Text>
                  <Text size="sm" c="dimmed" lineClamp={3}>
                    {app.description}
                  </Text>
                </Box>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      </SingleColumnLayoutMain>
      <SingleColumnLayoutFooter>
        <NisaFooter />
      </SingleColumnLayoutFooter>
    </SingleColumnLayout>
  );
}

export default ToolboxPage;
