import { useEffect, useState, ChangeEvent } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Header } from "../Header";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../../components/ui/dialog";
import { patchUpdateTeacher, fetchTeacher } from "../../../api/nisaAPI";
import {
  TeacherOut,
  TeacherPatchIn,
  Grade,
  Subjects,
  LessonSource,
} from "../../../api/nisaAPITypes";
import { getCachedUID } from "../../../utils/sessionUtils";
import { gradeOptions, subjectOptions } from "../../../constants";
import LessonSelector from "../../../components/LessonSelector";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// Defined type for teacher data that they are able to
// edit
type EditableTeacherData = Omit<TeacherPatchIn, "active">;

class UIDMissingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UIDMissingError";
  }
}

export default function AccountConfigurationPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<string>("");
  const [modalError, setModalError] = useState<string>("");
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<EditableTeacherData | null>(null);
  const { currentUser, logout } = useAuth();
  const queryClient = useQueryClient();

  const {
    isPending,
    error,
    data: teacherData,
  } = useQuery({
    queryKey: ["teacher", currentUser?.uid],
    queryFn: async (): Promise<TeacherOut> => {
      const token = await currentUser.getIdToken();
      const userId = getCachedUID();
      if (!userId) {
        throw new UIDMissingError(
          "UID is missing. Please log out + log back in.",
        );
      }
      return await fetchTeacher(userId, token);
    },
  });

  const { mutateAsync: patchTeacher } = useMutation({
    mutationFn: async ({
      patchData,
      id,
    }: {
      patchData: TeacherPatchIn;
      id: string;
    }): Promise<TeacherOut> => {
      const token = await currentUser.getIdToken();
      return await patchUpdateTeacher(id, patchData, token);
    },
  });

  useEffect(() => {
    if (error) {
      if (error instanceof UIDMissingError) {
        setGlobalError(error.message);
      } else {
        setGlobalError("Failed to fetch teacher dat");
      }
    } else {
      setGlobalError("");
    }
  }, [error]);

  const openEditModal = () => {
    if (teacherData) {
      const initialEditData: EditableTeacherData = {
        first_name: teacherData.first_name,
        last_name: teacherData.last_name,
        phone_number: teacherData.phone_number,
        grades_taught: teacherData.grades_taught,
        last_lesson_id: teacherData.last_lesson_id || null,
        last_lesson_source: teacherData.last_lesson_source || null,
        subjects_taught: teacherData.subjects_taught,
      };
      setEditData(initialEditData);
      setIsEditModalOpen(true);
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditData(null);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditData((prev) => (prev ? { ...prev, [name]: value } : prev));
  };

  const handleGradeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!editData) return;
    const { value, checked } = e.target;
    const grade = value as Grade;
    const currentGrades = editData.grades_taught ?? [];
    const updatedGrades = checked
      ? [...currentGrades, grade]
      : currentGrades.filter((g) => g !== grade);

    setEditData({
      ...editData,
      grades_taught: updatedGrades,
    });
  };

  const handleSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!editData) return;
    const { value, checked } = e.target;
    const subject = value as Subjects;
    const currentSubjects = editData.subjects_taught ?? [];
    const updatedSubjects = checked
      ? [...currentSubjects, subject]
      : currentSubjects.filter((s) => s !== subject);
    setEditData({
      ...editData,
      subjects_taught: updatedSubjects,
    });
  };

  const handleLessonSourceChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (!editData) return;
    const { value } = e.target;
    // If the value is "later", we set last_lesson_source to null
    const newValue = value === "later" ? null : (value as LessonSource);
    setEditData({
      ...editData,
      last_lesson_source: newValue,
    });
  };

  // Helper function to compare the original data with the edited data,
  // returning a diff object that only includes changed fields.
  const getTeacherPatchDiff = (
    original: TeacherOut,
    edited: EditableTeacherData,
  ): TeacherPatchIn => {
    const diff: TeacherPatchIn = {};
    if (edited.first_name !== original.first_name)
      diff.first_name = edited.first_name;
    if (edited.last_name !== original.last_name)
      diff.last_name = edited.last_name;
    if (edited.phone_number !== original.phone_number)
      diff.phone_number = edited.phone_number;
    if (
      JSON.stringify(edited.grades_taught) !==
      JSON.stringify(original.grades_taught)
    ) {
      diff.grades_taught = edited.grades_taught;
    }
    // The API will set both to null if only one is sent via patch
    // this is to prevent updating the ID with a LessonSource it
    // doesn't correspond to. Thus, if we update last_lesson_id or
    // last_lesson_source, we need to update patch with *both* values.
    if (
      edited.last_lesson_id !== original.last_lesson_id ||
      edited.last_lesson_source !== original.last_lesson_source
    ) {
      diff.last_lesson_id = edited.last_lesson_id;
      diff.last_lesson_source = edited.last_lesson_source;
    }
    if (
      JSON.stringify(edited.subjects_taught) !==
      JSON.stringify(original.subjects_taught)
    ) {
      diff.subjects_taught = edited.subjects_taught;
    }
    return diff;
  };

  const handleSave = async () => {
    if (!editData || !teacherData) return;
    setLoading(true);
    setModalError("");
    try {
      const patchData = getTeacherPatchDiff(teacherData, editData);

      // If nothing has changed, just close the modal.
      if (Object.keys(patchData).length === 0) {
        closeEditModal();
        setLoading(false);
        return;
      }

      // Call the patch update function
      await patchTeacher({ patchData: patchData, id: teacherData.id });
      closeEditModal();
      queryClient.invalidateQueries({
        queryKey: ["teacher", currentUser?.uid],
      });
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.detail) {
        setModalError(error.response.data.detail);
      } else {
        setModalError("Failed to update teacher data");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setGlobalError("");
    try {
      await logout();
    } catch {
      setGlobalError("Failed to log out");
    }
  };
  if (isPending) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        <p className="mt-4 text-lg text-gray-900">Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      {/* Background Layers */}
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-10">
        <Header onLogout={handleLogout} />
      </div>

      <main
        className="container mx-auto p-6 flex-grow flex flex-col items-center"
        style={{ paddingTop: "100px" }}
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-8">
          Account Configuration
        </h1>
        <div className="w-full max-w-5xl bg-white bg-opacity-40 rounded-lg shadow-lg p-6">
          {globalError && <p className="text-red-500 mb-4">{globalError}</p>}
          {loading && <p>Loading...</p>}
          {teacherData && (
            <div className="bg-white bg-opacity-30 rounded-lg overflow-hidden shadow-lg p-6">
              <p>
                <strong>First Name:</strong> {teacherData.first_name}
              </p>
              <p>
                <strong>Last Name:</strong> {teacherData.last_name}
              </p>
              <p>
                <strong>Grades Taught:</strong>{" "}
                {[...(teacherData.grades_taught ?? [])]
                  .sort(
                    (a, b) => gradeOptions.indexOf(a) - gradeOptions.indexOf(b),
                  )
                  .join(", ")}
              </p>
              <p>
                <strong>Phone Number:</strong>{" "}
                {teacherData.phone_number.phone_number}
              </p>
              <p>
                <strong>Subjects Taught:</strong>{" "}
                {teacherData.subjects_taught.join(", ")}
              </p>
              <p>
                <strong>Last Lesson Taught:</strong>{" "}
                {teacherData.last_lesson?.display_name ?? "No lesson set"}
              </p>
              <Button onClick={openEditModal}>Edit</Button>
            </div>
          )}
        </div>
      </main>

      {isEditModalOpen && editData && (
        <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
          <DialogContent>
            <DialogHeader className="">
              <DialogTitle>Edit Your Account Information</DialogTitle>
              {modalError && (
                <p className="text-red-500 text-sm mt-2">{modalError}</p>
              )}
            </DialogHeader>
            <div className="space-y-4 overflow-y-auto max-h-[70vh] p-4">
              <div>
                <label className="block mb-1 font-semibold">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={editData.first_name || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={editData.last_name || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  placeholder="Phone Number (11 digits, e.g. 1XXXXXXXXXX)"
                  value={editData.phone_number?.phone_number || ""}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Grades Taught
                </label>
                <div className="flex flex-wrap gap-2">
                  {gradeOptions.map((grade: Grade) => (
                    <label key={grade} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value={grade}
                        checked={(editData.grades_taught ?? []).includes(grade)}
                        onChange={(e) => handleGradeChange(e)}
                        className="form-checkbox"
                      />
                      <span>{grade}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div>
                <label className="block mb-1 font-semibold">
                  Subjects Taught
                </label>
                <div className="flex flex-wrap gap-2">
                  {subjectOptions.map((subject: Subjects) => (
                    <label
                      key={subject}
                      className="flex items-center space-x-2"
                    >
                      <input
                        type="checkbox"
                        value={subject}
                        checked={(editData.subjects_taught ?? []).includes(
                          subject,
                        )}
                        onChange={(e) => handleSubjectChange(e)}
                        className="form-checkbox"
                      />
                      <span>{subject}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div>
                <p className="mb-2 text-sm text-gray-600">
                  Select a curriculum to guide you through selecting your last
                  lesson taught.
                </p>
              </div>
              <div>
                <label className="block mb-1 font-semibold">Curriculum</label>
                <select
                  name="last_lesson_source"
                  value={editData.last_lesson_source || ""}
                  onChange={(e) => {
                    handleLessonSourceChange(e);
                  }}
                  className="w-full p-2 border rounded"
                  required
                >
                  <option value="" disabled>
                    Select a lesson source
                  </option>
                  <option value="illustrative_mathematics">
                    Illustrative Mathematics
                  </option>
                  <option value="eureka_math">Eureka Math</option>
                  <option value="custom">Custom</option>
                  <option value="later">I Will Upload Lesson Later</option>
                </select>
              </div>
              {editData.last_lesson_source && (
                <div>
                  <LessonSelector
                    lessonSource={editData.last_lesson_source}
                    onLessonChange={(selectedLesson) => {
                      if (!editData) return;
                      setEditData({
                        ...editData,
                        last_lesson_id: selectedLesson.id,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <DialogFooter className="">
              <Button onClick={closeEditModal}>Cancel</Button>
              <Button onClick={handleSave} disabled={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
