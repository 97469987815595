import { useEffect, useRef, useState } from "react";
import { Button } from "../components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import { useCoach } from "../contexts/CoachProvider";
import PropTypes from "prop-types";
import { getCachedUserFullName } from "../utils/sessionUtils";

const Header = ({
  isUnderTheHoodVisible,
  toggleUnderTheHood,
  isChatVisible,
  toggleChat,
  onLogout,
  hasNewMessage,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { teachersMissingLessons } = useCoach();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    const coachFullName = getCachedUserFullName();
    setUserFullName(`${coachFullName}`);

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    onLogout();
    sessionStorage.clear();
    setIsDropdownOpen(false);
  };

  return (
    <header className="bg-white-600 bg-opacity-80 backdrop-filter backdrop-blur-sm text-white p-4 flex justify-between items-center relative z-10">
      <div className="flex items-center space-x-8 max-h-[40px]">
        <Link to="/">
          <h1 className="text-2xl font-light">
            <b>nisa</b> _controlcenter
          </h1>
        </Link>
        <Link to="/toolbox">
          <div className="inline-block overflow-hidden">
            <img
              src="/toolbox.png"
              alt="Toolbox"
              width="42"
              height="42"
              className="translate-y-[-100px] drop-shadow-[0_100px_0_white]"
            />
          </div>
        </Link>
      </div>
      <div className="flex items-center space-x-4">
        <Button
          onClick={toggleChat}
          variant="ghost"
          className={`text-white font-quicksand border-none hover:border hover:border-blue-200 ${isChatVisible ? "underline" : ""}`}
        >
          {isChatVisible ? "hide nisa.chat" : "nisa.chat"}
          {!isChatVisible && hasNewMessage && (
            <span className="absolute -top-1 -right-1 bg-red-500 rounded-full w-3 h-3"></span>
          )}
        </Button>
        <Button
          onClick={toggleUnderTheHood}
          variant="ghost"
          className={`text-white font-quicksand border-none hover:border hover:border-blue-200 ${isUnderTheHoodVisible ? "underline" : ""}`}
        >
          {isUnderTheHoodVisible ? "hide nisa.inside" : "nisa.inside"}
        </Button>
        <div className="relative" ref={dropdownRef}>
          <Button
            onClick={toggleDropdown}
            variant="ghost"
            className="text-white font-quicksand border-none hover:border hover:border-blue-200"
          >
            hi, {userFullName}
            {/* Show red dot if teachers are missing lesson materials */}
            {teachersMissingLessons.length > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 rounded-full w-3 h-3"></span>
            )}
          </Button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate("/")}
              >
                Dashboard
              </button>
              <button
                className="flex block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate("/teacher_config")}
              >
                Teacher Config
                {/* Show red dot if teachers are missing lesson materials */}
                {teachersMissingLessons.length > 0 && (
                  <span className="ml-2 bg-red-500 rounded-full w-2.5 h-2.5"></span>
                )}
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate("/lesson_config")}
              >
                Lesson Config
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => navigate("/coach/config")}
              >
                Coach Config
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={handleLogout}
              >
                Log out
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  isUnderTheHoodVisible: PropTypes.bool,
  toggleUnderTheHood: PropTypes.func,
  isChatVisible: PropTypes.bool,
  toggleChat: PropTypes.func,
  onLogout: PropTypes.func,
  currentUser: PropTypes.shape({
    accessToken: PropTypes.string,
  }),
  hasNewMessage: PropTypes.bool,
};

export default Header;
