import { Button, Group, Modal, Stack, Text, Title } from "@mantine/core";
import { LessonUnpackingSummaryResponse } from "@/api/nisaAPITypes";
import { notifications } from "@mantine/notifications";

interface SummaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  defaultValue?: string;
  summary: LessonUnpackingSummaryResponse;
}

export default function SummaryModal({
  isOpen,
  onClose,
  summary,
}: SummaryModalProps) {
  const handleCopyToClipboard = () => {
    const text = `Lesson Summary:
${summary.summary}

Key Highlight:
${summary.highlight}

Deep Dive:
${summary.deep_dive}

Modifications:
${summary.modifications}`;
    navigator.clipboard.writeText(text);
    notifications.show({
      title: "Text Copied",
      message: "The text has been copied to your clipboard.",
      color: "green",
    });
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={<Title order={3}>Lesson Summary</Title>}
      centered
      overlayProps={{ backgroundOpacity: 0.25 }}
      transitionProps={{ transition: "pop", duration: 300 }}
      size="lg"
    >
      <Text size="sm" mb="xs" c="dimmed">
        Review the lesson summary below.
      </Text>
      <Stack>
        <Group>
          <Text fw={500} size="sm">
            Summary:
          </Text>
          <Text size="sm">{summary.summary}</Text>
        </Group>
        <Group>
          <Text fw={500} size="sm">
            Key Highlight:
          </Text>
          <Text size="sm">{summary.highlight}</Text>
        </Group>
        <Group>
          <Text fw={500} size="sm">
            Deep Dive:
          </Text>
          <Text size="sm">{summary.deep_dive}</Text>
        </Group>
        <Group>
          <Text fw={500} size="sm">
            Modifications:
          </Text>
          <Text size="sm">{summary.modifications}</Text>
        </Group>
      </Stack>
      <Group justify="flex-end" mt="lg">
        <Button variant="light" color="blue" onClick={handleCopyToClipboard}>
          Copy to Clipboard
        </Button>
        <Button variant="subtle" color="gray" onClick={onClose}>
          Close
        </Button>
      </Group>
    </Modal>
  );
}
