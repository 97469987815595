import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import PulseIndicator from "../../../ui/PulseIndicator/PulseIndicator";

interface QuestionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (question: string, audioBlob?: Blob) => Promise<void>;
  onCancel: () => void;
}

export default function QuestionModal({
  isOpen,
  onClose,
  onSubmit,
  onCancel,
}: QuestionModalProps) {
  const [question, setQuestion] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.start(1000);
      setIsRecording(true);
      setError(null);
    } catch (error) {
      console.error("Error starting recording:", error);
      setError("Could not access microphone. Please check your permissions.");
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      const stream = mediaRecorderRef.current.stream;
      stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);

      // Create audio blob and submit directly
      const audioBlob = new Blob(chunksRef.current, { type: "audio/webm" });
      setIsSubmitting(true);
      try {
        await onSubmit("", audioBlob);
      } catch {
        setError("Failed to submit audio. Please try again.");
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
      setQuestion("");
      onClose();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (question.trim()) {
      setIsSubmitting(true);
      try {
        await onSubmit(question.trim());
      } catch {
        setError("Failed to submit question. Please try again.");
        setIsSubmitting(false);
        return;
      }
      setIsSubmitting(false);
      setQuestion("");
      onClose();
    }
  };

  const handleCancel = () => {
    if (isRecording) {
      const stream = mediaRecorderRef.current?.stream;
      stream?.getTracks().forEach((track) => track.stop());
    }
    setQuestion("");
    setIsRecording(false);
    setIsSubmitting(false);
    onClose();
    onCancel();
  };

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && isRecording) {
        const stream = mediaRecorderRef.current.stream;
        mediaRecorderRef.current.stop();
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isRecording]);

  return (
    <Modal
      opened={isOpen}
      onClose={handleCancel}
      title="Ask a Question"
      centered
      overlayProps={{ backgroundOpacity: 0.25 }}
      transitionProps={{ transition: "pop", duration: 300 }}
      size="lg"
    >
      {isSubmitting ? (
        <Stack align="center" py="xl">
          <Loader size="lg" />
          <Text c="dimmed">
            {isRecording ? "Processing audio..." : "Submitting question..."}
          </Text>
        </Stack>
      ) : (
        <form onSubmit={handleSubmit}>
          <Textarea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Type your question here..."
            minRows={4}
            autosize
            autoFocus
            disabled={isRecording}
          />
          {error && (
            <Text c="red" size="sm" mt="xs">
              {error}
            </Text>
          )}
          <Group justify="space-between" mt="lg">
            <Button
              variant={isRecording ? "filled" : "light"}
              color={isRecording ? "red" : "gray"}
              onClick={isRecording ? stopRecording : startRecording}
              leftSection={
                isRecording ? (
                  <Group>
                    <PulseIndicator color="white" />
                  </Group>
                ) : (
                  <Text>🎤</Text>
                )
              }
            >
              {isRecording ? "Stop Recording" : "Record"}
            </Button>
            <Group>
              <Button variant="subtle" color="gray" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!question.trim() || isRecording}
                variant="filled"
              >
                Submit Question
              </Button>
            </Group>
          </Group>
        </form>
      )}
    </Modal>
  );
}
