import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Xarrow, { Xwrapper, useXarrow } from "react-xarrows";
import { LessonSegmentGroup, LessonSegment } from "./types";
import { X } from "lucide-react"; // Import the X icon from lucide-react

interface LessonBuilderSpaceProps {
  segmentGroups: LessonSegmentGroup[];
  onDrop: (
    e: React.DragEvent<HTMLDivElement>,
    rowIndex: number,
    segmentIndex: number,
  ) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    segment: LessonSegment,
    fromBuilder: boolean,
    rowIndex: number,
    segmentIndex: number,
  ) => void;
  onSegmentUpdate: (
    rowIndex: number,
    segmentIndex: number,
    newContent: string,
  ) => void;
  onAddBlankSegment: (rowIndex: number) => void;
  onDeleteSegment: (rowIndex: number, segmentIndex: number) => void;
}

const animals = [
  "Lion",
  "Elephant",
  "Giraffe",
  "Zebra",
  "Monkey",
  "Penguin",
  "Kangaroo",
  "Dolphin",
  "Tiger",
  "Koala",
];

const LessonBuilderSpace: React.FC<LessonBuilderSpaceProps> = ({
  segmentGroups,
  onDrop,
  onDragOver,
  onDragStart,
  onSegmentUpdate,
  onAddBlankSegment,
  onDeleteSegment,
}) => {
  const [editingSegment, setEditingSegment] = useState<{
    rowIndex: number;
    segmentIndex: number;
  } | null>(null);
  const [editingContent, setEditingContent] = useState<string>("");
  const [arrows, setArrows] = useState<{ start: string; end: string }[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const updateXarrow = useXarrow();

  useEffect(() => {
    const newArrows: { start: string; end: string }[] = [];
    segmentGroups.forEach((row, rowIndex) => {
      if (rowIndex < segmentGroups.length - 1) {
        row.segments.forEach((segment, segmentIndex) => {
          segmentGroups[rowIndex + 1].segments.forEach(
            (nextSegment, nextSegmentIndex) => {
              newArrows.push({
                start: `segment-${rowIndex}-${segmentIndex}`,
                end: `segment-${rowIndex + 1}-${nextSegmentIndex}`,
              });
            },
          );
        });
      }
    });
    setArrows(newArrows);
  }, [segmentGroups]);

  useEffect(() => {
    const handleScroll = () => {
      updateXarrow();
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [updateXarrow]);

  const handleCreateBlankSegment = (rowIndex: number) => {
    onAddBlankSegment(rowIndex);
  };

  const getGroupAnimal = (rowIndex: number, segmentIndex: number) => {
    if (segmentGroups[rowIndex].segments.length > 1) {
      const animalIndex =
        (rowIndex * segmentGroups[rowIndex].segments.length + segmentIndex) %
        animals.length;
      return animals[animalIndex];
    }
    return null;
  };

  const animalColors = {
    Lion: "#FFA500",
    Elephant: "#808080",
    Giraffe: "#FFD700",
    Zebra: "#000000",
    Monkey: "#8B4513",
    Penguin: "#000080",
    Kangaroo: "#8B0000",
    Dolphin: "#00CED1",
    Tiger: "#FFA500",
    Koala: "#808080",
  };

  const handleSegmentClick = (
    rowIndex: number,
    segmentIndex: number,
    content: string,
  ) => {
    setEditingSegment({ rowIndex, segmentIndex });
    setEditingContent(content);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditingContent(e.target.value);
  };

  const handleTextBlur = () => {
    if (editingSegment) {
      onSegmentUpdate(
        editingSegment.rowIndex,
        editingSegment.segmentIndex,
        editingContent,
      );
      setEditingSegment(null);
    }
  };

  const handleDeleteSegment = (rowIndex: number, segmentIndex: number) => {
    onDeleteSegment(rowIndex, segmentIndex);
  };

  return (
    <div className="lesson-builder-space" ref={containerRef}>
      <h1 className="lesson-builder-header">Lesson Lab</h1>
      <Xwrapper>
        {segmentGroups.map((row, rowIndex) => (
          <div
            key={`row-${rowIndex}`}
            className={`lesson-row ${row.segments.length === 1 ? "single-segment" : ""}`}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, rowIndex, row.segments.length)}
          >
            {row.segments.map((segment, segmentIndex) => {
              const groupAnimal = getGroupAnimal(rowIndex, segmentIndex);
              const isEditing =
                editingSegment?.rowIndex === rowIndex &&
                editingSegment?.segmentIndex === segmentIndex;
              return (
                <div
                  key={`segment-${rowIndex}-${segmentIndex}`}
                  id={`segment-${rowIndex}-${segmentIndex}`}
                  className={`lesson-segment ${row.segments.length === 1 ? "full-width" : ""} ${isEditing ? "editing" : ""}`}
                  draggable={!isEditing}
                  onDragStart={(e) =>
                    onDragStart(e, segment, true, rowIndex, segmentIndex)
                  }
                  onClick={() =>
                    handleSegmentClick(
                      rowIndex,
                      segmentIndex,
                      segment.full_text,
                    )
                  }
                  onDragOver={onDragOver}
                  onDrop={(e) => {
                    e.stopPropagation();
                    onDrop(e, rowIndex, segmentIndex);
                  }}
                >
                  {groupAnimal && (
                    <div
                      className="group-header"
                      style={{
                        color:
                          animalColors[
                            groupAnimal as keyof typeof animalColors
                          ],
                      }}
                    >
                      Group {groupAnimal}
                    </div>
                  )}
                  <h3>{segment.segment_title}</h3>
                  {isEditing ? (
                    <textarea
                      value={editingContent}
                      onChange={handleTextChange}
                      onBlur={handleTextBlur}
                      autoFocus
                    />
                  ) : (
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {segment.full_text}
                    </ReactMarkdown>
                  )}
                  {segment.coach_related && (
                    <span className="coach-related-badge">
                      action step practice
                    </span>
                  )}
                  <button
                    className="delete-segment-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSegment(rowIndex, segmentIndex);
                    }}
                  >
                    <X size={16} />
                  </button>
                </div>
              );
            })}
          </div>
        ))}
        {arrows.map((arrow, index) => (
          <Xarrow
            key={`arrow-${index}`}
            start={arrow.start}
            end={arrow.end}
            color="#007bff"
            strokeWidth={2}
            path="grid"
            startAnchor="bottom"
            endAnchor="top"
          />
        ))}
      </Xwrapper>
      <div
        className="drop-zone new-row"
        onDragOver={onDragOver}
        onDrop={(e) => onDrop(e, segmentGroups.length, 0)}
        onClick={() => handleCreateBlankSegment(segmentGroups.length)}
      >
        Click or drop here to add new lesson block
      </div>
    </div>
  );
};

export default LessonBuilderSpace;
