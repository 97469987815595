import { Fragment, useState } from "react";
import {
  ActionIcon,
  Collapse,
  Group,
  Loader,
  Paper,
  Progress,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  HandIcon,
  PauseIcon,
  PencilIcon,
  PencilOffIcon,
  PlayIcon,
} from "lucide-react";

type StageType = "overview" | "analysis" | "framing";
type ColorType = "blue" | "grape" | "teal";

interface StageInfo {
  title: string;
  description: string;
  color: ColorType;
}

interface ControlBarProps {
  onRaiseHand: () => void;
  onTogglePause: () => void;
  onToggleWhiteboard: () => void;
  isPaused: boolean;
  isWhiteboardVisible: boolean;
  isHandRaised: boolean;
  isLoading: boolean;
  isLessonFinished?: boolean;
  currentStage: StageType | null;
}

export default function ControlBar({
  onRaiseHand,
  onTogglePause,
  onToggleWhiteboard,
  isPaused,
  isWhiteboardVisible,
  isHandRaised,
  isLoading,
  isLessonFinished = false,
  currentStage,
}: ControlBarProps) {
  const [isTrackerExpanded, setIsTrackerExpanded] = useState(false);

  const activeStage = currentStage || "overview";
  const stages: StageType[] = ["overview", "analysis", "framing"];
  const currentIndex = stages.indexOf(activeStage);

  const stageInfo: Record<StageType, StageInfo> = {
    overview: {
      title: "Overview",
      description: "Understanding the lesson objective and flow",
      color: "blue",
    },
    analysis: {
      title: "Analysis",
      description: "Working through student problems and concepts",
      color: "grape",
    },
    framing: {
      title: "Framing",
      description: "Adapting the lesson to student needs",
      color: "teal",
    },
  };

  const info = stageInfo[activeStage];

  return (
    <Paper shadow="sm" radius="md" withBorder>
      <Stack gap={0}>
        {/* Stage Tracker Section */}
        <Paper
          p="xs"
          bg={`${info.color}.1`}
          onClick={() => setIsTrackerExpanded(!isTrackerExpanded)}
          style={(theme) => ({
            cursor: "pointer",
            "&:hover": {
              backgroundColor: theme.colors[info.color][1],
            },
            borderBottom: `1px solid ${theme.colors[info.color][2]}`,
            borderBottomLeftRadius: "0%",
            borderBottomRightRadius: "0%",
          })}
        >
          <Group gap="md" justify="space-between">
            <Group gap="xs">
              {isLoading && <Loader size={14} />}
              <Text size="sm" fw={600} c={`${info.color}.7`}>
                {info.title}
              </Text>
              <Text
                size="xs"
                px="xs"
                py={2}
                bg={`${info.color}.6`}
                c="white"
                fw={500}
                style={(theme) => ({
                  borderRadius: theme.radius.xl,
                })}
              >
                {currentIndex + 1}/3
              </Text>
            </Group>

            <Group gap={4} flex={1}>
              {stages.map((stage, index) => (
                <Fragment key={stage}>
                  <Progress
                    value={100}
                    size="sm"
                    color={
                      index <= currentIndex ? stageInfo[stage].color : "gray.0"
                    }
                    flex={1}
                  />
                  {index < stages.length - 1 && (
                    <div
                      style={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        border: `1px solid ${
                          index < currentIndex
                            ? `var(--mantine-color-${stageInfo[stages[index + 1]].color}-3)`
                            : "var(--mantine-color-gray-3)"
                        }`,
                      }}
                    />
                  )}
                </Fragment>
              ))}
            </Group>
          </Group>

          <Collapse in={isTrackerExpanded}>
            <Stack gap="xs" pt="xs" px="xs">
              <Text size="xs" c="dimmed">
                {info.description}
              </Text>
              <Group justify="space-between">
                {stages.map((stage) => (
                  <Text
                    key={stage}
                    size="xs"
                    fw={activeStage === stage ? 600 : 400}
                    c={
                      activeStage === stage
                        ? `${stageInfo[stage].color}.7`
                        : "dimmed"
                    }
                  >
                    {stageInfo[stage].title}
                  </Text>
                ))}
              </Group>
            </Stack>
          </Collapse>
        </Paper>

        {/* Control Bar Section */}
        <Group align="center" justify="center" gap={60} p="md">
          <Tooltip label={isHandRaised ? "Lower Hand" : "Raise Hand"}>
            <ActionIcon
              variant={isHandRaised ? "light" : "subtle"}
              color={isHandRaised ? "yellow" : "gray"}
              disabled={isLessonFinished}
              onClick={onRaiseHand}
              size="lg"
              radius="md"
            >
              <HandIcon size={30} />
            </ActionIcon>
          </Tooltip>

          <Tooltip label={isPaused ? "Play" : "Pause"}>
            <ActionIcon
              variant="subtle"
              color="gray"
              onClick={onTogglePause}
              size="lg"
              radius="md"
            >
              {isPaused ? <PlayIcon size={30} /> : <PauseIcon size={30} />}
            </ActionIcon>
          </Tooltip>

          <Tooltip
            label={isWhiteboardVisible ? "Hide Whiteboard" : "Show Whiteboard"}
          >
            <ActionIcon
              variant={isWhiteboardVisible ? "light" : "subtle"}
              color={isWhiteboardVisible ? "blue" : "gray"}
              onClick={onToggleWhiteboard}
              size="lg"
              radius="md"
            >
              {isWhiteboardVisible ? (
                <PencilOffIcon size={30} />
              ) : (
                <PencilIcon size={30} />
              )}
            </ActionIcon>
          </Tooltip>
        </Group>
      </Stack>
    </Paper>
  );
}
