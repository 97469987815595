import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuth } from "../contexts/AuthContext";
import { Grade, LessonOut, LessonSource } from "../api/nisaAPITypes";
import { fetchLessonsBySource } from "../api/nisaAPI";
import { gradeOptions } from "../constants";
import { useQuery } from "@tanstack/react-query";

interface LessonSelectorProps {
  lessonSource: LessonSource;
  // eslint-disable-next-line no-unused-vars
  onLessonChange: (selectedLesson: LessonOut) => void;
}

const LessonSelector: React.FC<LessonSelectorProps> = ({
  lessonSource,
  onLessonChange,
}) => {
  const { currentUser } = useAuth();

  const [mode, setMode] = useState<"browse" | "search">("browse");

  // Browse mode state
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedModule, setSelectedModule] = useState("");

  // Search mode state
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(false);

  // Common selected lesson
  const [selectedLesson, setSelectedLesson] = useState<LessonOut | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  // Fetch all lessons for the given source
  const { error, data: lessons = [] } = useQuery({
    queryKey: ["lessonSource", lessonSource],
    queryFn: async (): Promise<LessonOut[]> => {
      const token = await currentUser?.getIdToken();
      return fetchLessonsBySource(lessonSource, token);
    },
  });

  // Log any errors from the query
  if (error) {
    console.error("Failed to fetch lessons: ", error);
  }

  // Reset state whenever lessonSource changes
  useEffect(() => {
    setMode("browse");
    setSelectedGrade("");
    setSelectedModule("");
    setSelectedLesson(null);
    setSearchTerm("");
    setShowDropdown(false);
    setSelectedIndex(-1);
  }, [lessonSource]);

  // Browse mode functions
  // Filter for browse mode: by selectedGrade and selectedModule
  const browseFilteredLessons: LessonOut[] = useMemo(() => {
    return lessons
      .filter((lesson) => {
        let ok = true;
        if (selectedGrade) {
          ok = ok && lesson.grade === selectedGrade;
        }
        if (selectedModule) {
          ok = ok && lesson.module_name === selectedModule;
        }
        return ok;
      })
      .sort((a, b) => {
        const topicComparison = a.topic_name.localeCompare(b.topic_name);
        if (topicComparison !== 0) return topicComparison;
        return a.display_name.localeCompare(b.display_name);
      });
  }, [lessons, selectedGrade, selectedModule]);

  // Compute unique, sorted grades from the lessons
  const grades: Grade[] = useMemo(() => {
    const uniqueGrades = lessons
      .map((lesson: LessonOut) => lesson.grade)
      .filter((grade, index, arr) => arr.indexOf(grade) === index);
    return uniqueGrades.sort(
      (a, b) => gradeOptions.indexOf(a) - gradeOptions.indexOf(b),
    );
  }, [lessons]);

  const moduleOptions = useMemo(() => {
    if (!selectedGrade) return [];
    const modules: string[] = lessons
      .filter((lesson) => lesson.grade === selectedGrade)
      .map((lesson) => lesson.module_name);
    return modules.filter(
      (module, index, arr) => arr.indexOf(module) === index,
    );
  }, [lessons, selectedGrade]);

  // Handle lesson selection in browse mode
  const handleBrowseLessonSelect = (lesson: LessonOut) => {
    setSelectedLesson(lesson);
    onLessonChange(lesson);
    // Sync to search mode
    setSearchTerm(lesson.display_name);
  };

  // Search mode functions
  // Filter for search mode: if searchTerm is empty, show all lessons
  const searchFilteredLessons = useMemo(() => {
    const lowerTerm = searchTerm.toLowerCase();
    if (!lowerTerm) return lessons;
    return lessons.filter((lesson) =>
      lesson.display_name.toLowerCase().includes(lowerTerm),
    );
  }, [lessons, searchTerm]);

  // Handle selection in search mode
  const handleSearchLessonSelect = (lesson: LessonOut) => {
    setSelectedLesson(lesson);
    setSearchTerm(lesson.display_name);
    setShowDropdown(false);
    onLessonChange(lesson);

    // Sync to browse mode
    setSelectedGrade(lesson.grade);
    setSelectedModule(lesson.module_name);
  };

  // Keyboard navigation for the search dropdown
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!showDropdown) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prev) =>
          Math.min(prev + 1, searchFilteredLessons.length - 1),
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
        break;
      case "Enter":
        e.preventDefault();
        if (searchFilteredLessons[selectedIndex]) {
          handleSearchLessonSelect(searchFilteredLessons[selectedIndex]);
        }
        break;
      case "Escape":
        setShowDropdown(false);
        break;
      default:
        break;
    }
  };

  // Reset highlighted index whenever the filtered list changes
  useEffect(() => {
    setSelectedIndex(-1);
  }, [searchFilteredLessons]);

  // Click outside to close search dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <div className="flex items-center gap-2 mb-4 text-sm">
        <span
          onClick={() => setMode("browse")}
          className={`cursor-pointer px-2 py-1 border rounded ${
            mode === "browse" ? "bg-blue-100 text-blue-700" : "text-gray-600"
          }`}
        >
          Browse
        </span>
        <span
          onClick={() => setMode("search")}
          className={`cursor-pointer px-2 py-1 border rounded ${
            mode === "search" ? "bg-blue-100 text-blue-700" : "text-gray-600"
          }`}
        >
          Search
        </span>
      </div>

      {mode === "browse" && (
        <>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-sm">Grade</label>
            <select
              value={selectedGrade}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setSelectedGrade(e.target.value);
                setSelectedModule("");
                setSelectedLesson(null);
              }}
              className="w-full p-2 border rounded text-sm"
            >
              <option value="">Select a grade</option>
              {grades.map((grade) => (
                <option key={grade} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
          </div>
          {selectedGrade && (
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-sm">Module</label>
              <select
                value={selectedModule}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setSelectedModule(e.target.value);
                  setSelectedLesson(null);
                }}
                className="w-full p-2 border rounded text-sm"
              >
                <option value="">Select a module</option>
                {moduleOptions.map((module) => (
                  <option key={module} value={module}>
                    {module}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-sm">Lesson</label>
            <select
              value={selectedLesson ? JSON.stringify(selectedLesson) : ""}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const lesson = JSON.parse(e.target.value) as LessonOut;
                handleBrowseLessonSelect(lesson);
              }}
              className="w-full p-2 border rounded text-sm"
              disabled={browseFilteredLessons.length === 0}
            >
              <option value="" disabled>
                {browseFilteredLessons.length === 0
                  ? "No lessons available"
                  : "Select a lesson"}
              </option>
              {browseFilteredLessons.map((lesson) => (
                <option key={lesson.id} value={JSON.stringify(lesson)}>
                  {lesson.display_name}
                </option>
              ))}
            </select>
          </div>
        </>
      )}

      {mode === "search" && (
        <div>
          <label className="block mb-1 font-semibold text-sm">
            Select Lesson
          </label>
          <input
            type="text"
            className="w-full p-2 border rounded text-sm"
            placeholder="Click or type to filter lessons..."
            value={searchTerm}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              setSelectedLesson(null);
              setShowDropdown(true);
            }}
            onFocus={() => {
              setShowDropdown(true);
            }}
            onKeyDown={handleKeyDown}
          />

          {showDropdown && (
            <ul className="absolute z-10 w-full bg-white border rounded shadow mt-1 max-h-60 overflow-y-auto text-sm">
              {searchFilteredLessons.length === 0 ? (
                <li className="p-2 text-gray-500">No lessons found</li>
              ) : (
                searchFilteredLessons.map((lesson, index) => (
                  <li
                    key={lesson.id}
                    className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                      index === selectedIndex ? "bg-blue-50" : ""
                    }`}
                    onMouseEnter={() => setSelectedIndex(index)}
                    onClick={() => handleSearchLessonSelect(lesson)}
                  >
                    {/* Container for displaying lesson for selection*/}
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-900">
                        {lesson.display_name}
                      </span>
                      <span className="ml-4 text-xs text-gray-500 whitespace-nowrap">
                        {lesson.grade} - {lesson.module_name}
                      </span>
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default LessonSelector;
