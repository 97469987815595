import { Grade, Subjects } from "./api/nisaAPITypes";

// Grades we support
export const gradeOptions: Grade[] = [
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "HS",
];

export const subjectOptions: Subjects[] = ["English", "Mathematics", "Science"];
