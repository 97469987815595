import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../../components/ui/alert";
import { useAuth } from "../../../../contexts/AuthContext";
import { Header } from "../../Header";
import { GroupedLessons, groupLessons } from "../../../../utils/lessons";
import { fetchLessonBuilderLessons } from "../../../../api/nisaAPI";
import {
  LessonBuilderLessonOut,
  LessonBuilderLessonsOut,
} from "../../../../api/nisaAPITypes";
import LessonPlanViewer from "../LessonBuilderLesson/LessonPlanViewer";
import { ChevronDown, ChevronRight } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { useQuery } from "@tanstack/react-query";

type CollapsibleProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  initialOpen?: boolean;
  indent?: number;
};

const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  children,
  initialOpen = false,
  indent = 0,
}) => {
  const [open, setOpen] = useState(initialOpen);
  const baseIndent = 8;

  return (
    <div style={{ marginLeft: indent }}>
      <div
        onClick={() => setOpen(!open)}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          userSelect: "none",
          margin: "4px 0",
          borderBottom: "1px solid #ccc",
          paddingBottom: "2px",
        }}
      >
        <div style={{ marginRight: "8px" }}>
          {open ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </div>
        <div style={{ fontWeight: 600 }}>{title}</div>
      </div>
      {open && <div style={{ marginLeft: baseIndent }}>{children}</div>}
    </div>
  );
};

const LessonBuilderLessonListPage: React.FC = () => {
  const [groupedLessons, setGroupedLessons] = useState<GroupedLessons>({});
  const [selectedLesson, setSelectedLesson] =
    useState<LessonBuilderLessonOut | null>(null);
  const { currentUser, logout } = useAuth();
  const [globalError, setGlobalError] = useState<string>("");
  const navigate = useNavigate();

  const {
    isPending,
    error,
    data: lessonBuilderLessonsOut,
  } = useQuery({
    queryKey: ["lessonBuilderLessonsOut", currentUser?.uid],
    queryFn: async (): Promise<LessonBuilderLessonsOut> => {
      const token = await currentUser.getIdToken();
      const data = await fetchLessonBuilderLessons(token);
      return data;
    },
  });

  useEffect(() => {
    if (lessonBuilderLessonsOut) {
      const groups = groupLessons(lessonBuilderLessonsOut.lessons);
      setGroupedLessons(groups);
    }
  }, [lessonBuilderLessonsOut]);

  useEffect(() => {
    if (error) {
      setGlobalError("Failed to fetch lessons.");
    } else {
      setGlobalError("");
    }
  }, [error]);

  const handleLogout = async () => {
    setGlobalError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setGlobalError("Failed to log out");
    }
  };

  if (isPending) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        <p className="mt-4 text-lg text-gray-900">Loading...</p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen" style={{ fontFamily: "sans-serif" }}>
      {/* Background Layers */}
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      {/* Fixed Header */}
      <div className="fixed top-0 left-0 right-0 z-10">
        <Header onLogout={handleLogout} />
      </div>

      {/* Error Alert */}
      {globalError && (
        <div className="fixed top-16 z-20 w-full flex justify-center">
          <Alert message={globalError} className="max-w-lg" />
        </div>
      )}

      {/* Page Content */}
      <div
        className="lessons-page"
        style={{
          marginTop: "75px",
          display: "flex",
          minHeight: "calc(100vh - 75px)",
        }}
      >
        {/* Sidebar / Navigation */}
        <aside
          className="lesson-navigation"
          style={{
            width: "240px",
            backgroundColor: "#f9f9f9",
            padding: "8px",
            borderRight: "1px solid #ccc",
            overflowY: "auto",
            position: "sticky",
            top: "75px",
            height: "calc(100vh - 75px)",
          }}
        >
          {Object.keys(groupedLessons).map((lessonSource) => {
            const gradeKeys = Object.keys(groupedLessons[lessonSource]);
            return (
              <Collapsible
                key={lessonSource}
                title={
                  <h2 style={{ margin: 0, fontSize: "1rem" }}>
                    {lessonSource}
                  </h2>
                }
                initialOpen={false}
                indent={0}
              >
                {gradeKeys.map((grade) => {
                  const moduleKeys = Object.keys(
                    groupedLessons[lessonSource][grade],
                  );
                  return (
                    <Collapsible
                      key={grade}
                      title={
                        <h3 style={{ margin: 0, fontSize: "0.95rem" }}>
                          Grade: {grade}
                        </h3>
                      }
                      initialOpen={false}
                      indent={8}
                    >
                      {moduleKeys.map((module) => {
                        const topicKeys = Object.keys(
                          groupedLessons[lessonSource][grade][module],
                        );
                        return (
                          <Collapsible
                            key={module}
                            title={
                              <h4 style={{ margin: 0, fontSize: "0.9rem" }}>
                                Module: {module}
                              </h4>
                            }
                            initialOpen={false}
                            indent={16}
                          >
                            {topicKeys.map((topic) => {
                              const lessons =
                                groupedLessons[lessonSource][grade][module][
                                  topic
                                ];
                              return (
                                <Collapsible
                                  key={topic}
                                  title={
                                    <h5
                                      style={{ margin: 0, fontSize: "0.85rem" }}
                                    >
                                      Topic: {topic}
                                    </h5>
                                  }
                                  initialOpen={false}
                                  indent={24}
                                >
                                  {lessons.map((lesson) => (
                                    <div
                                      key={lesson.id}
                                      style={{ margin: "4px 0" }}
                                    >
                                      <button
                                        onClick={() =>
                                          setSelectedLesson(lesson)
                                        }
                                        style={{
                                          background: "#fff",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px",
                                          padding: "4px 8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Lesson:{" "}
                                        {lesson.source_lesson.lesson_name}
                                      </button>
                                    </div>
                                  ))}
                                </Collapsible>
                              );
                            })}
                          </Collapsible>
                        );
                      })}
                    </Collapsible>
                  );
                })}
              </Collapsible>
            );
          })}
        </aside>

        {/* Main Content / Lesson Detail */}
        <main
          className="lesson-detail"
          style={{
            flex: 1,
            padding: "16px",
            backgroundColor: "#fff",
            overflowY: "auto",
          }}
        >
          {selectedLesson ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <h2>Lesson Details</h2>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() =>
                    navigate(`/teacher/lesson_builder/${selectedLesson.id}`)
                  }
                >
                  Edit in Lesson Builder
                </Button>
              </div>
              <LessonPlanViewer
                lessonPlan={selectedLesson.built_markdown || ""}
                onClose={() => setSelectedLesson(null)}
                showSave={false}
                showEmail={false}
                backButtonLabel="Back to Lessons"
              />
            </>
          ) : (
            <p>Select a lesson to view details.</p>
          )}
        </main>
      </div>
    </div>
  );
};

export default LessonBuilderLessonListPage;
