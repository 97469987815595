import { ReactNode } from "react";
import { Link } from "react-router-dom";

type BaseHeaderProps = {
  children: ReactNode;
};

export function BaseHeader({ children }: BaseHeaderProps): JSX.Element {
  return (
    <header
      className="bg-white-600 bg-opacity-80
                 backdrop-filter backdrop-blur-sm text-white
                 p-4 flex justify-between items-center
                 relative z-10"
    >
      <div className="flex items-center space-x-8 max-h-[40px]">
        <Link to="/">
          <h1 className="text-2xl font-light">
            <b>nisa</b> _controlcenter
          </h1>
        </Link>
        <Link to="/toolbox">
          <div className="inline-block overflow-hidden">
            <img
              src="/toolbox.png"
              alt="Toolbox"
              width="42"
              height="42"
              className="translate-y-[-100px] drop-shadow-[0_100px_0_white]"
            />
          </div>
        </Link>
      </div>
      <div className="flex items-center space-x-4">{children}</div>
    </header>
  );
}
