import { createTheme } from "@mantine/core";

const theme = createTheme({
  fontFamily: '"Geologica", sans-serif',
  headings: {
    fontFamily: '"Geologica", sans-serif',
  },
});

export default theme;
