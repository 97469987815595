import { useState } from "react";
import { Button, Flex, Modal, Paper, Stack, Text, Title } from "@mantine/core";
import {
  LessonUnpackingCheatSheetResponse,
  UnpackerScheduleTextIn,
  UnpackerSessionModel,
} from "@/api/nisaAPITypes";
import { DateTimePicker } from "@mantine/dates";
import "@mantine/dates/styles.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { scheduleUnpackerTextMessage } from "../../../../api/nisaAPI";
import { useAuth } from "../../../../contexts/AuthContext";
import { notifications, showNotification } from "@mantine/notifications";

interface CheatSheetModalProps {
  isOpen: boolean;
  onClose: () => void;
  cheatSheet: LessonUnpackingCheatSheetResponse;
  session: UnpackerSessionModel;
}

export default function CheatSheetModal({
  isOpen,
  onClose,
  cheatSheet,
  session,
}: CheatSheetModalProps) {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();

  const [scheduledTime, setScheduledTime] = useState<Date | null>(null);

  const { mutateAsync: scheduleTextMutateAsync } = useMutation({
    mutationFn: async ({
      sessionID,
      payloadData,
    }: {
      sessionID: string;
      payloadData: UnpackerScheduleTextIn;
    }) => {
      const token = await currentUser.getIdToken();
      return await scheduleUnpackerTextMessage(sessionID, payloadData, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["unpacker", "session", session.id],
      });
    },
  });

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={<Title order={3}>Three Things to Remember</Title>}
      centered
      overlayProps={{ backgroundOpacity: 0.25 }}
      transitionProps={{ transition: "pop", duration: 300 }}
      size="md"
    >
      <Stack gap={6}>
        {cheatSheet.takeaways.map((takeaway, index) => (
          <Paper
            key={index}
            shadow="xs"
            p="md"
            radius="md"
            style={{ backgroundColor: getBackgroundColor(index) }}
          >
            <Flex align="flex-start">
              <Flex
                w={8}
                h={8}
                justify="center"
                align="center"
                fs="bold"
                bg={getBadgeColor(index)}
                color={getTextColor(index)}
              >
                {index + 1}
              </Flex>
              <Text ml="sm">{takeaway}</Text>
            </Flex>
          </Paper>
        ))}

        {/* Display scheduled text info if available */}
        {currentUser && session.cheat_sheet_text_timestamp && (
          <Text size="sm">
            Scheduled Text:{" "}
            {new Date(session.cheat_sheet_text_timestamp).toLocaleString()}
          </Text>
        )}

        {/* DateTimePicker for scheduling the text */}
        {currentUser && !session.cheat_sheet_text_timestamp && (
          <DateTimePicker
            label="Select Schedule Time"
            placeholder="Pick date and time"
            value={scheduledTime}
            onChange={setScheduledTime}
            valueFormat="MM/DD/YYYY hh:mm A"
          />
        )}
      </Stack>

      <Flex mt={6} gap={3} justify="flex-end">
        {currentUser && (
          <Button
            variant="light"
            color="green"
            disabled={!scheduledTime || !!session?.cheat_sheet_text_id}
            onClick={async () => {
              try {
                await scheduleTextMutateAsync({
                  sessionID: session.id,
                  payloadData: {
                    scheduled_time: scheduledTime!.toISOString(),
                  },
                });
              } catch (error) {
                showNotification({
                  title: "Text Scheduling Error",
                  message:
                    error instanceof Error
                      ? error.message
                      : "There was an error scheduling the text.",
                  color: "red",
                });
              }
            }}
          >
            Schedule Text
          </Button>
        )}
        <Button
          variant="light"
          color="blue"
          onClick={() => {
            const text = `Three Things to Remember:\n\n${cheatSheet.takeaways
              .map((takeaway, index) => `${index + 1}. ${takeaway}`)
              .join("\n\n")}`;
            navigator.clipboard.writeText(text);
            notifications.show({
              title: "Text Copied",
              message: "The text has been copied to your clipboard.",
              color: "green",
            });
          }}
        >
          Copy to Clipboard
        </Button>
        <Button variant="light" color="gray" onClick={onClose}>
          Close
        </Button>
      </Flex>
    </Modal>
  );
}

const getBackgroundColor = (index: number) => {
  return ["#EFF6FF", "#ECFDF5", "#F5F3FF"][index];
};

const getBadgeColor = (index: number) => {
  return ["#DBEAFE", "#D1FAE5", "#EDE9FE"][index];
};

const getTextColor = (index: number) => {
  return ["#2563EB", "#047857", "#6D28D9"][index];
};
