import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LessonOut, LessonSource } from "../../../api/nisaAPITypes";
import {
  Anchor,
  Button,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import LessonSelector from "../../LessonSelector";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext";
import {
  SimpleLayout,
  SimpleLayoutFooter,
  SimpleLayoutMain,
} from "../../layouts/SimpleLayout";
import NisaFooter from "../../ui/Footer";
import { getOrCreateLessonBuilderLesson } from "../../../api/nisaAPI";

export const lessonSourceOptions: LessonSource[] = ["illustrative_mathematics"];

const LessonBuilderLandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [coachAction, setCoachAction] = useState<string>("");
  const [selectedLesson, setSelectedLesson] = useState<LessonOut | null>(null);
  const selectedLessonRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<{ openModal: () => void }>(null);
  const { currentUser } = useAuth();

  // Mutation for creating a lesson
  const { mutateAsync: createLesson, isPending: isCreating } = useMutation({
    mutationFn: async (lessonData: {
      source_id: string;
      coach_action?: string;
    }): Promise<{ id: string }> => {
      const token = await currentUser?.getIdToken();
      return await getOrCreateLessonBuilderLesson(lessonData, token);
    },
  });

  // Effect to scroll to the selected lesson content when it appears
  useEffect(() => {
    if (selectedLesson && selectedLessonRef.current) {
      selectedLessonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedLesson]);

  const startLesson = async () => {
    if (selectedLesson) {
      try {
        const lesson = await createLesson({
          source_id: selectedLesson.id,
          coach_action: coachAction,
        });
        navigate(`/lesson-builder/${lesson.id}`);
      } catch (error) {
        console.error("Failed to initialize Lesson Lab:", error);
        notifications.show({
          color: "red",
          title: "Failed to initialize Lesson Lab",
          message: "Please try again.",
        });
      }
    }
  };

  return (
    <SimpleLayout>
      <SimpleLayoutMain>
        <Container size="lg" py="8rem" px="md">
          <Stack maw={768} mx="auto" mb={64} align="center">
            <Stack gap="xs">
              <Paper
                bg="blue.6"
                px="lg"
                py="md"
                style={{ display: "inline-block" }}
              >
                <Group>
                  <Text fz={80} mr="sm">
                    ✏️
                  </Text>
                  <Title order={1} size={80}>
                    Lesson Lab
                  </Title>
                </Group>
              </Paper>
              <Anchor
                size="xl"
                c="gray.6"
                fw={100}
                onClick={() => footerRef.current?.openModal()}
                ta="left"
              >
                an experiment
              </Anchor>
            </Stack>
          </Stack>
          <Stack maw={640} mx="auto">
            <LessonSelector
              lessonSource="illustrative_mathematics"
              onLessonChange={(lesson) => {
                setSelectedLesson(lesson);
              }}
            />
          </Stack>

          {selectedLesson && (
            <Stack ref={selectedLessonRef} maw={640} mx="auto" mt="xl">
              <Paper shadow="sm" p="md" radius="md" withBorder>
                <Stack>
                  <Title order={3}>{selectedLesson.display_name}</Title>
                  <Group>
                    <Text fw={500}>Grade:</Text>
                    <Text>{selectedLesson.grade}</Text>
                  </Group>
                  {selectedLesson.curriculum_name && (
                    <Group>
                      <Text fw={500}>Curriculum:</Text>
                      <Text>{selectedLesson.curriculum_name}</Text>
                    </Group>
                  )}
                  <Group>
                    <Text fw={500}>Module:</Text>
                    <Text>{selectedLesson.module_name}</Text>
                  </Group>
                  <Group>
                    <Text fw={500}>Topic:</Text>
                    <Text>{selectedLesson.topic_name}</Text>
                  </Group>
                  <Group>
                    <Text fw={500}>Lesson:</Text>
                    <Text>{selectedLesson.lesson_name}</Text>
                  </Group>
                </Stack>
              </Paper>

              <Paper shadow="sm" p="md" radius="md" withBorder>
                <Stack>
                  {/* Coach Action Field */}
                  <Text fw={500} size="sm">
                    Optional: Add Tips/Focus Areas Your Coach Has Provided
                  </Text>
                  <Textarea
                    placeholder="Tell me about specific areas your coach has provided feedback on..."
                    minRows={4}
                    value={coachAction}
                    onChange={(e) => setCoachAction(e.target.value)}
                  />
                  <Button
                    variant="filled"
                    onClick={startLesson}
                    fullWidth
                    disabled={isCreating}
                  >
                    Start Building
                  </Button>
                </Stack>
              </Paper>
            </Stack>
          )}
        </Container>
      </SimpleLayoutMain>
      <SimpleLayoutFooter>
        <NisaFooter ref={footerRef} />
      </SimpleLayoutFooter>
    </SimpleLayout>
  );
};

export default LessonBuilderLandingPage;
